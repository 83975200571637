import { i18n } from '@/plugins/i18n'

export default [
  /* --------------------- Recepción de facturas - (inbox) -------------------- */
  {
    meta: {
      title: i18n.tc('BillReception', 2)
    },
    path: '/purchases/bills/inbox',
    name: 'purchases-bills-inbox-index',
    component: () => import(/* webpackChunkName: "purchases-bills-inbox-index" */ '@/views/purchases/bills/inbox/Index.vue')
  },
  /* --------------------------- Facturas de compras - (Bills) -------------------------- */
  {
    meta: {
      title: i18n.tc('PurchaseDocument')
    },
    path: '/purchases/bills/:id/view',
    name: 'purchases-bills-view',
    component: () => import(/* webpackChunkName: "purchases-bills-view" */ '@/views/purchases/bills/View.vue')
  },
  {
    meta: {
      title: i18n.tc('PurchaseDocument', 2)
    },
    path: '/purchases/bills',
    name: 'purchases-bills-index',
    component: () => import(/* webpackChunkName: "purchases-bills-index" */ '@/views/purchases/bills/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreatePurchaseDocument')
    },
    path: '/purchases/bills/create',
    name: 'purchases-bills-create',
    component: () => import(/* webpackChunkName: "purchases-bills-create" */ '@/views/purchases/bills/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('CreatePurchaseDocument')
    },
    path: '/purchases/bills/duplicate/:id',
    name: 'purchases-bills-duplicate',
    component: () => import(/* webpackChunkName: "purchases-bills-duplicate" */ '@/views/purchases/bills/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditPurchaseDocument')
    },
    path: '/purchases/bills/edit/:id',
    name: 'purchases-bills-edit',
    component: () => import(/* webpackChunkName: "purchases-bills-edit" */ '@/views/purchases/bills/Edit.vue')
  },

  /* -------------- Notas de ajustes (Débito) - (BillDebitNotes) -------------- */
  {
    meta: {
      title: i18n.tc('AdjustmentDebitNote')
    },
    path: '/purchases/bill-debit-notes/:id/view',
    name: 'purchases-bill-debit-notes-view',
    component: () => import(/* webpackChunkName: "purchases-bill-debit-notes-view" */ '@/views/purchases/bill-debit-notes/View.vue')
  },
  {
    meta: {
      title: i18n.tc('AdjustmentDebitNote')
    },
    path: '/purchases/bill-debit-notes',
    name: 'purchases-bill-debit-notes-index',
    component: () => import(/* webpackChunkName: "purchases-bill-debit-notes-index" */ '@/views/purchases/bill-debit-notes/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateAdjustmentDebitNote')
    },
    path: '/purchases/bill-debit-notes/create',
    name: 'purchases-bill-debit-notes-create',
    component: () => import(/* webpackChunkName: "purchases-bill-debit-notes-create" */ '@/views/purchases/bill-debit-notes/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateAdjustmentDebitNote')
    },
    path: '/purchases/bill-debit-notes/create/:idParent',
    name: 'purchases-bill-debit-notes-create-by-id-parent',
    component: () => import(/* webpackChunkName: "purchases-bill-debit-notes-by-id-parent" */ '@/views/purchases/bill-debit-notes/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditAdjustmentDebitNote')
    },
    path: '/purchases/bill-debit-notes/edit/:id',
    name: 'purchases-bill-debit-notes-edit',
    component: () => import(/* webpackChunkName: "purchases-bill-debit-notes-edit" */ '@/views/purchases/bill-debit-notes/Edit.vue')
  },

  /* ---------------- Remisiones de compras (PurchaseRemission) --------------- */
  {
    meta: {
      title: i18n.tc('PurchaseRemission')
    },
    path: '/purchases/remissions/:id/view',
    name: 'purchases-remissions-view',
    component: () => import(/* webpackChunkName: "purchases-remissions-view" */ '@/views/purchases/remissions/View.vue')
  },
  {
    meta: {
      title: i18n.tc('PurchaseRemission', 2)
    },
    path: '/purchases/remissions',
    name: 'purchases-remissions-index',
    component: () => import(/* webpackChunkName: "purchases-remissions-index" */ '@/views/purchases/remissions/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('PurchaseRemissionCreate', 1)
    },
    path: '/purchases/remissions/create',
    name: 'purchases-remissions-create',
    component: () => import(/* webpackChunkName: "purchases-remissions-create" */ '@/views/purchases/remissions/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('DuplicatePurchaseRemission', 1)
    },
    path: '/purchases/remissions/duplicate/:id',
    name: 'purchases-remissions-duplicate',
    component: () => import(/* webpackChunkName: "purchases-remissions-duplicate" */ '@/views/purchases/remissions/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('PurchaseRemissionEdit', 1)
    },
    path: '/purchases/remissions/edit/:id',
    name: 'purchases-remissions-edit',
    component: () => import(/* webpackChunkName: "purchases-remissions-edit" */ '@/views/purchases/remissions/Edit.vue')
  },

  /* ------------------ Ordenes de compras - (PurchaseOrder) ------------------ */
  {
    meta: {
      title: i18n.tc('PurchaseOrder')
    },
    path: '/purchases/orders/:id/view',
    name: 'purchases-orders-view',
    component: () => import(/* webpackChunkName: "purchases-orders-view" */ '@/views/purchases/orders/View.vue')
  },
  {
    meta: {
      title: i18n.tc('PurchaseOrder', 2)
    },
    path: '/purchases/orders',
    name: 'purchases-orders-index',
    component: () => import(/* webpackChunkName: "purchases-orders-index" */ '@/views/purchases/orders/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreatePurchaseOrder')
    },
    path: '/purchases/orders/create',
    name: 'purchases-orders-create',
    component: () => import(/* webpackChunkName: "purchases-orders-create" */ '@/views/purchases/orders/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('DuplicatePurchaseOrder')
    },
    path: '/purchases/orders/duplicate/:id',
    name: 'purchases-orders-duplicate',
    component: () => import(/* webpackChunkName: "purchases-orders-create" */ '@/views/purchases/orders/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditPurchaseOrder')
    },
    path: '/purchases/orders/edit/:id',
    name: 'purchases-orders-edit',
    component: () => import(/* webpackChunkName: "purchases-orders-edit" */ '@/views/purchases/orders/Edit.vue')
  }
]

export default {
  Absences: 'Ausencias',
  Accept: 'Aceptar',
  AccessAndSecurity: 'Acceso y seguridad | Accesos y seguridad',
  Security: 'Seguridad',
  AccessDenied: 'Acceso denegado',
  Account: 'Cuenta|Cuentas',
  AccountableMovement: 'Movimiento Contable|Movimientos Contables',
  Accounted: 'Contabilizado|Contabilizados',
  AccountGainOnSale: 'Cuenta ganancia en venta',
  Accounting: 'Contabilidad',
  AccountingAccount: 'Cuenta Contable|Cuentas Contables',
  AccountingAdvisoryServices: 'Asesoría Contable y Tributaria',
  AccountingClosure: 'Cierre contable|Cierres contables',
  AccountingDocument: 'Documento Contable | Documentos contables',
  AccountingDocumentTemplate: 'Plantilla de documento contable|Plantillas de documentos contables',
  AccountingEntry: 'Asiento Contable|Asientos Contables',
  AccountingOutsourcing: 'Outsorcing Contable',
  AccountingReport: 'Reporte Contable|Reportes Contables',
  AccountLostAttrition: 'Cuenta perdida por desgaste',
  AccountLostOnSale: 'Cuenta perdida en venta',
  AccountNumber: 'Número Cuenta',
  AccountPse: 'Cuenta (Pse)',
  AccountStatement: 'Estado de cuenta|Estados de cuenta',
  AppNewRelease: 'Actualización Disponible',
  AppNewReleaseMessage: 'Estamos listos para aplicar las mejoras descargadas es necesario recargar tu navegador, pero no te preocupes te vamos a dar 5 minutos para que termines y después el sistema lo va hacer automáticamente, si deseas realizar inmediatamente este proceso, debes dar click en "Recargar Ahora"',
  AppReleaseNewContent: 'Nuevo contenido de la aplicación, lo estamos descargando te avisaremos cuando estemos listos',
  AppReloading: 'Estamos descargando los archivos necesarios para que todo funcione correctamente, danos un momento más por favor',
  AccountType: 'Tipo cuenta',
  Accrued: 'Devengado|Devengados',
  Action: 'Acción|Acciones',
  ActionSuccessfullyExecuted: '¡Acción ejecutada con éxito!',
  Activated: 'Activado',
  Active: 'Activo|Activa',
  Activity: 'Actividad|Actividades',
  ActivityResult: 'Resultado de la actividad | Resultados de la actividad',
  ActivityType: 'Tipo de actividad | Tipos de actividad',
  Add: 'Agregar | Adicionar',
  AddAdvance: 'Agregar avance | Agregar avances',
  AddAliaddoTechnologyProvider: 'Seleccionar a "Aliaddo" como proveedor tecnológico',
  AddPosRegister: 'Agregar una caja',
  AddBranch: 'Agregar una sucursal | Agregar sucursales',
  AddCollaborator: 'Agregar colaborador|Agregar colaboradores',
  AddComment: 'Agrega un comentario',
  AddDefaultReply: 'Agregar respuesta predeterminada',
  AddDownPayment: 'Agregar anticipo | Agregar anticipos',
  Added: 'Agregado|Agregados',
  AddExpenseType: 'Agregar tipo de gasto',
  AddFolder: 'Añadir folder',
  AddGroup: 'Añadir Grupo',
  AddHoliday: 'Añadir Festivo | Añadir Festivo ',
  AdditionalData: 'Datos adicionales',
  AddMarker: 'Agregar marcador|Agregar marcadores',
  AddNewConcept: 'Adicionar nuevo concepto',
  AddNumerationAliaddo: 'Agregar nueva numeración aquí en Aliaddo',
  AddProduct: 'Agregar producto',
  AddQuote: 'Agregar cotización | Agregar cotizaciones',
  AddReferral: 'Agregar referido',
  AddResolution: 'Agregar resolución',
  Address: 'Dirección | Direcciones',
  AddressIsRequired: '¡La dirección es obligatoria! | ¡Las direcciones son obligatorias!',
  AddressName: 'Nombre de dirección',
  AddRow: 'Agregar fila | Agregar filas',
  AddSaleOrder: 'Agregar orden de venta| Agregar ordenes de venta',
  AddServiceBonusToAccrual: 'Sumar prima de servicios a devengado',
  AddSeveranceInterestToAccruals: 'Sumar intereses de cesantías a devengado',
  AddSeveranceToAccruals: 'Sumar cesantías a devengado',
  AddShortcut: 'Agregar un acceso rápido',
  AddTax: 'Agregar impuesto | Agregar impuestos',
  AddTaxesAndWithholding: 'Agragar Impuestos y Retenciones',
  AddVacationValueToAccrual: 'Sumar valor de vacaciones a devengado',
  AddWithholding: 'Agragar retención | Agragar retenciones',
  AdjustCost: 'Ajustar costos',
  Adjustment: 'Ajuste | Ajustes',
  AdjustmentDebitNote: 'Notas de ajuste (débito)',
  AdjustmentInvalidatedDian: 'Nota de ajuste invalida por la DIAN',
  AdjustmentNote: 'Notas de ajuste',
  AdjustmentNoteEdit: 'Nota de ajuste: Editar',
  AdjustmentNoteReplace: 'Nota de ajuste: Remplazar',
  AdjustmentNoteReplacement: 'Realizar Nota de Ajuste Reemplazo',
  AdjustmentRange: '¡El ajuste debe ser mayor o igual a -2 o menor o igual a 2!',
  AdjustStock: 'Ajustar existencias',
  Administration: 'Administración',
  Administrator: 'Administrador | Administradores',
  Advance: 'Avance | Avances',
  AdvancedSearch: 'Búsqueda avanzada',
  AdvanceFrom: 'Anticipo de | Anticipos de',
  AdvanceGreaterThanDocument: '¡El valor del anticipo es mayor al total del documento!',
  AdvancePayment: 'Anticipo | Anticipos',
  AdvancesGreaterThanZero: 'El valor de los anticipos debe ser superior a 0 (cero) e inferior al saldo',
  Agents: 'Agente | Agentes ',
  Aggregates: 'Agregado|Agregados',
  Agreement: 'Política de SLA | Políticas de SLA',
  AIU: 'A.I.U',
  AIUnotAddedInvoice: 'Las bases del AIU No se suman al total de la factura',
  Alert: 'Alerta',
  AlertLargerStock: 'El stock debe ser menor o igual a las existencias del lote',
  AlertSerialSold: 'Los seriales {value} ya fueron vendidos o tienen una orden de producción, verifica',
  AliaddoAdministrativeSoftware: 'Software Administrativo Aliaddo',
  AliaddoLink: 'aliaddo.com',
  AliaddoStore: 'Aliaddo Store',
  All: 'Todo|Todos',
  AllAccountsAssigned: 'Todas las cuentas deben ser asignadas',
  AllBranches: 'Todas las sucursales',
  AllCategories: 'Todas las categorías',
  AllDocumentTypes: 'Todos los Tipos de Documentos',
  AllowCancelProductionOrder: 'Se permite cancelar, este proceso anula la salida de inventario y controla el proceso por etapas o cantidad segun configuración al finalizar el control puede terminar la orden de producción, este proceso realiza el ingreso del producto final',
  AllUser: 'Todos los usuarios',
  AllWarehouses: 'Todas las bodegas',
  Alphabetically: 'Alfabéticamente',
  AlreadyRegisteredElectronicBiller: 'Ya estoy registrado como facturador electrónico',
  Analytic: 'Analítica|Analíticas',
  Another: 'Otro|Otros',
  Answer: 'Responder',
  Approve: 'Aprobar',
  Approved: 'Aprobado',
  April: 'Abril',
  Archive: 'Archivo',
  AreYouSure: '¿Estás seguro/a?',
  Article: 'Artículo | Artículos',
  ArticlePropertie: 'Propiedades del artículo | Propiedades del los artículos ',
  AssetRevaluationAccount: 'Cuenta revaluación activo (DB) | Cuenta revaluación activo (CR)',
  AssignAccountForEachExpense: 'Asigne una cuenta a cada uno de sus gastos',
  AssignBatch: 'Asignar lote| Asignar lotes',
  AssignedTo: 'Asignado a',
  AssignNameCategory: '¡Asigna un nombre a su categoría!',
  AssignSerial: 'Asignar serial|Asignar seriales',
  Associate: 'Vincular',
  AssociateNumberingPrefix: 'Asociar prefijo o rango de numeración',
  AssociatePrefixDianElectronicInvoicingPortal: 'Con la nueva resolución de numeración, asocia el prefijo o rango de numeración en el portal de FACTURACIÓN ELECTRÓNICA de la DIAN',
  AssociateThePrefixElectronicInvoicing: 'Una vez cuentes con la nueva resolución de numeración, debes asociar el prefijo o rango de numeración en el portal FACTURACIÓN ELECTRÓNICA de la DIAN en el siguiente enlace',
  Attach: 'Adjuntar',
  AttachedFile: 'Archivo adjunto | Archivos adjuntos',
  AttachFile: 'Adjuntar archivo | Adjuntar archivos',
  Attention: 'Atención | Atenciones',
  Attribute: 'Atributo|Atributos',
  AttributeProductVariant: 'Atributos de variantes de un producto',
  AuditAndStatutoryAudit: 'Auditoría y Revisoría Fiscal',
  August: 'Agosto',
  Authentication: 'Autenticación',
  Auxiliar: 'Auxiliar|Auxiliares',
  Available: 'Disponible | Disponibilidad',
  AvailableCredit: 'Crédito disponible | Créditos disponibles',
  Avatar: 'Avatar',
  Back: 'Atrás',
  Backup: 'Copia de Respaldo|Copias de Respaldo',
  BackupsHelper: 'Las copias de respaldo se ejecutarán en máximo 12 horas, tan pronto se haya ejecutado la tarea se te notificará por email.',
  BadToGood: 'De malo a bueno',
  Balance: 'Saldo|Saldos',
  BalanceAt: 'Saldo a',
  BalanceByParentCostCenter: 'Balance por Centro de Costos Padre',
  BalanceByPerson: 'Balance por Tercero|Balances por Tercero',
  Bank: 'Banco',
  BankConcept: 'Concepto bancario|Conceptos Bancarios',
  BankConciliation: 'Conciliación Bancaria',
  Batch: 'Lote|Lotes',
  BatchesReport: 'Reporte de lotes',
  BatchHasExpired: 'El lote {itemBatchCode} ya ha vencido',
  BatchTransactionReport: 'Reporte de transacciones de lotes',
  BillingPosType: '¿Tu facturación es tipo POS (Punto de venta y Cajas)?',
  BillReception: 'Recepción de Factura|Recepción de Facturas',
  Bot: 'Bot',
  BoxInUseNotDelete: 'Esta caja está en uso, no se permite eliminarla. Tiene facturas emitidas o gastos registrados',
  BoxOpenNotDelete: 'Esta caja aún sigue abierta, no se permite eliminarla',
  Branch: 'Sucursal|Sucursales',
  brancheAssignedDisabled: 'Las sucursales que se te asignó está deshabilitada, habilitala en "Configuración > Preferencias > Sucursales" o contacta a tu administrador',
  BranchesDisabledOrNotExist: '¡Las sucursales que se te asignó está deshabilitada o no exíste!',
  BranchIsMain: '¿La sucursal es la principal?',
  BranchManagers: 'Administradores de sucursal o punto de venta',
  BranchName: 'Nombre de la sucursal',
  BranchNotDeleteHasDocument: 'Esta sucursal está en uso, no se permite eliminarla. Tiene documentos comerciales emitidos',
  BranchPosInvoicerequireCostCenter: 'La sucursal con facturacion tipo POS, requiere del centro de costos en los datos predeterminados',
  BranchPosInvoicerequireWarehouse: 'La sucursal con facturacion tipo POS, requiere de la bodega en los datos predeterminados',
  Brand: 'Marca|Marcas',
  BrandName: 'Nombre de la marca',
  Budget: 'Presupuesto|Presupuestos',
  BusinessHour: 'Horarios laborales',
  ByInvoice: 'Por Factura | Por facturas',
  ByParentCostCenter: 'Por centro de costos padre',
  ByPerson: 'Por tercero',
  Call: 'Llamada',
  Cancel: 'Cancelar',
  CancelAndExit: 'Cancelar y salir',
  CancelCancellation: 'Cancelar anulación',
  CancelCancellationRecord: '¿Deseas cancelar la anulación de los registros?',
  Canceled: 'Cancelada|Cancelado',
  CannedResponse: 'Respuesta predeterminada | Respuestas predeterminadas',
  CantRemoveFirstRow: '¡No puedes eliminar la primera fila!',
  CantSaveExpensesWithoutNames: 'No se pueden guardar gastos sin nombres',
  CardUrl: 'Enlace de la tarjeta | Enlace de las tarjetas',
  Carrier: 'Transportista',
  CaseSource: 'Fuente del caso',
  Cash: 'Efectivo | Contado',
  Cashier: 'Cajero | Cajeros',
  Category: 'Categoría|Categorías',
  CategoryProductsFixedAsset: 'Categorías de productos o servicios y activos fijos',
  Causation: 'Causación|Causaciones',
  CellPhoneNumber: 'Número de celular',
  Change: 'Cambiar',
  ChangeCurrency: 'Cambiar moneda | Cambiar monedas',
  ChangeDateSale: '¿Permitir cambiar la fecha en la venta?',
  ChangeDiscountItemSale: '¿Permitir cambiar el descuento de los artículos en la venta?',
  ChangeInEquity: 'Cambio en el Patrimonio|Cambios en el Patrimonio',
  ChangeInEquityByParentCostCenter: 'Cambio en el Patrimonio por Centro de Costos Padre|Cambios en el Patrimonio por Centro de Costos Padre',
  ChangeLog: 'Log de cambios|Logs de cambios',
  ChangeOwner: 'Cambiar propietario',
  ChangePassword: 'Cambiar Contraseña',
  ChangePriceItemSale: '¿Permitir cambiar el precio de los artículos en la venta?',
  ChangeStageSuccess: '¡Cambio de etapa realizado con éxito!',
  ChangeStatus: 'Cambiar estado',
  ChangeStatusToOpen: 'Cambiar el estado a abierto',
  ChangeVendor: 'Cambiar vendedor',
  ChamberCommerce: 'Camara de comercio',
  Charge: 'Cargo | Cargos',
  ChargeAppliedInvoice: 'Cargos aplicados a nivel de factura, no afecta la base de los impuestos',
  Checkbook: 'Talonario',
  CheckSettingPurchaseRemission: 'Verifique la configuración del tipo de documento "Remisión de compra" en la sucursal {branchName}, ve a "Configuración > Ventas > Consecutivos',
  CheckSettingSaleRemission: 'Verifique la configuración del tipo de documento "Remisión de venta" en la sucursal {branchName}, ve a "Configuración > Ventas > Consecutivos',
  CheckTestSetStatus: 'CONSULTAR ESTADO DEL SET DE PRUEBA',
  Chronometer: 'Cronómetro',
  City: 'Ciudad | Ciudades',
  CityIsRequired: '¡La ciudad es obligatoria!',
  CleanFilter: 'Limpiar filtro|Limpiar filtros',
  ClickEnterNewNumbering: 'Seleccionar resolución de numeración',
  ClickOnIt: 'Click en él',
  ClickStartTestSet: 'Realizar el set de pruebas',
  ClickToSeeStockProduct: 'Haga clic para ver las existencias de cada producto',
  ClickToSelect: 'Click para seleccionar',
  ClickToUpdateData: 'ACTUALIZA LOS DATOS DE TU EMPRESA',
  Close: 'Cerrar',
  CloseActivity: 'Cerrar la actividad | Cerrar las actividades',
  Closed: 'Cerrado|Cerrados|Cerrada|Cerradas',
  ClosedDate: 'Fecha de cierre',
  CloseRegister: 'Cerrar caja | Cerrar cajas',
  ClosureAccounting: 'Cierre contable|Cierres contables',
  Code: 'Código | Códigos',
  Collaborator: 'Colaborador|Colaboradores',
  Collection: 'Recaudo|Recaudos',
  Color: 'Color | Colores',
  ComingSoon: 'Próximamente',
  CommaSeparated: 'Separados por comas',
  CommentIsEmpty: '¡El comentario está vacío!',
  Comment: 'Comentario|Comentarios',
  CommercialDocument: 'Documento comercial | Documentos comerciales',
  Commission: 'Comisión|Comisiones',
  CommissionsReport: 'Reporte de Comisiones',
  CommissionType: 'Tipo de comisión | Tipos de comisiones',
  Company: 'Compañía|Compañías',
  CompanyInformation: 'Información de la compañía | Información de la empresa',
  CompanyName: 'Nombre de la empresa',
  CompanySegments: 'Segmento de empresa | Segmentos de empresas',
  Comparative: 'Comparativo|Comparativos',
  Competitor: 'Competidor|Competidores',
  CompetitorName: 'Nombre del competidor',
  CompetitorNameRequired: 'El Nombre del competidor es obligatorio',
  Completed: 'Completada|Completadas',
  CompleteFormCorrectly: '¡Completa el formulario correctamente!',
  ComputerBilling: 'Factura por computador',
  Concept: 'Concepto | Conceptos',
  ConfigureConcecutiveSaleOrder: 'Debes configurar los consecutivos de la "orden de venta" para permitir crear este documento, ve a "Configuración > Ventas > Consecutivos"',
  ConfigureConcecutiveSaleRemision: 'Debes configurar los consecutivos de la Remisión de venta para permitir crear este documento, ve a "Configuración > Ventas > Consecutivos"',
  ConfigureBizDocument: 'Configurar documento|Configurar documentos',
  ConfigureDocumentSaleOrder: 'Debe configurar los documentos comerciales para permitirle crear el documento "orden de venta"',
  ConfigureDocumentSaleRemision: 'Debe configurar los documentos comerciales para permitirle crear el documento Remisión de venta',
  ConfirmationApproveOrder: 'Has seleccionado un registro está seguro de aprobar la orden',
  ConfirmationCancelOrder: 'Has seleccionado un registro esta seguro de cancelar la orden',
  ConfirmationCompleteOrder: 'Has seleccionado un registro está seguro de terminar la orden',
  ConfirmationProcessedOrder: 'Has seleccionado un registro está seguro de procesar la orden',
  ConfirmPassword: 'Confirmar contraseña',
  Consecutive: 'Consecutivo|Consecutivos',
  ConsecutiveOrReference: 'Consecutivo o referencia',
  ConsignmentRemittance: 'Remisión en consignación',
  Consult: 'Consultar',
  ConsultAdjustment: 'Consultar ajuste | Consultar ajustes',
  ConsultEmployee: 'Consultar empleado | Consultar empleados',
  ConsultInventory: 'Consultar inventario | Consultar inventarios',
  ConsultInvoice: 'Consultar factura | Consultar facturas',
  ConsultPayroll: 'Consultar nómina | Consultar nóminas',
  Contact: 'Contacto | Contactos',
  ContactInformation: 'Información de contacto',
  ContactLifeCycle: 'Ciclo de vida del contacto',
  ContactLifeCycleStage: 'Etapas del ciclo de vida del contacto',
  ContactPartner: 'Contacto asociado',
  ContactSegments: 'Segmento de contacto | Segmentos de contactos',
  ContainDateAndHour: 'La actividad debe contener una fecha y hora de inicio',
  ContingencyCheckbook: 'Talonario por contingencia',
  ContingencyInvoice: 'Factura electronica de contingencia',
  ContractType: 'Tipo contrato',
  Convert: 'Convertir | Convertirse',
  ConvertSaleOrder: 'Convertir a orden de venta | Convertir a ordenes de ventas',
  Cost: 'Costo | Costos',
  CostCenter: 'Centro de Costo|Centros de Costos',
  CostValuesMustBeGreaterThanZero: 'Los valores de los costos deben ser mayor a cero (0)',
  Country: 'País | Países',
  CountryIsRequired: '¡El país es obligatorio!',
  CrateNote: 'Crear nota',
  Create: 'Crear',
  CreateAccountingDocumentTemplate: 'Crear plantilla de documento contable',
  CreateActivity: 'Crear actividad|Crear actividades',
  CreateAdjustmentDebitNote: 'Crear Nota de ajuste (débito)',
  CreateAdvance: 'Crear anticipo | Crear anticipos',
  CreateAndSetAsClose: 'Crear y establecer como cerrar',
  CreateAttribute: 'Crear atributo',
  CreateBankingConcept: 'Crear concepto bancario',
  CreateBatch: 'Crear lote | Crear lotes',
  CreateBranch: 'Crear sucursal',
  CreateBrand: 'Crear marca',
  CreateBusinessDocument: 'Crear documento comercial',
  CreateCategory: 'Crear categoría | Crear categorías',
  CreateCommission: 'Crear comisión|Crear comisiones',
  CreateCreditNote: 'Crear Nota crédito en venta',
  Created: 'Creado',
  CreatedAt: 'Creado el',
  CreatedByDateAndAuthor: 'Creado el {date} por {author}',
  CreatedDate: 'Fecha de creación',
  CreateDeal: 'Crear Negocio | Crear Negocios',
  CreateDebitNote: 'Crear nota débito',
  CreateDispatchOrder: 'Crear órden de despacho',
  CreateEmployee: 'Crear Empleado | Crear Empleados',
  CreateExpense: 'Crear gasto | Crear gastos',
  CreateFirstOneSerial: 'No hay seriales disponibles, crea el primero',
  CreateFixedAssetCategory: 'Crear categoría activo fijo',
  CreateFolder: 'Crear carpeta | Crear carpetas',
  CreateInventory: 'Crear inventario',
  CreateNew: 'Crear nuevo | Crear nuevos',
  CreateProductCategory: 'Crear categoría de productos',
  CreateProductionOrder: 'Crear orden de producción',
  CreatePurchaseInvoice: 'Crear factura de compra',
  CreatePurchaseDocument: 'Crear documento de compra',
  CreatePurchaseOrder: 'Crear orden de compra',
  CreateQuote: 'Crear cotización | Crear cotizaciones',
  CreateRecurrenceInvoice: 'Crear factura recurrente',
  CreateRol: 'Crear rol | Crear roles',
  CreateSaleDebitNote: 'Crear Nota débito en venta',
  CreateSaleInvoice: 'Crear factura de venta',
  CreateSaleOrder: 'Crear orden de venta',
  CreateSaleRemission: 'Crear Remisión de venta',
  CreateSerial: 'Crear serial | Crear seriales',
  CreateShortcut: 'Crear acceso rápido',
  CreateTax: 'Crear impuesto',
  CreateWithholding: 'Crear retención',
  CreateTemplate: 'Crear Plantilla|Crear Plantillas',
  CreateTicket: 'Crear Ticket',
  CreateUser: 'Crear Usuario | Crear Usuarios',
  CreateVoucher: 'Crear comprobante',
  CreationDateAscending: 'Fecha de creación ascendente',
  CreationDateDescending: 'Fecha de creación descendente',
  Credit: 'Crédito|Créditos',
  CreditCard: 'Tarjeta de crédito|Tarjetas de crédito',
  CreditNote: 'Nota crédito | Notas crédito',
  CreditQuota: 'Cupo de crédito',
  Crm: 'CRM',
  CrmReport: 'CRM | CRM',
  Currency: 'Moneda | Monedas',
  Current: 'Vigente | Vigentes',
  CurrentPassword: 'Contraseña actual',
  Customer: 'Cliente|Clientes',
  CustomerAccountStatement: 'Estado de cuenta de clientes',
  CustomerCreditExceeded: '¡El cupo de crédito del cliente ha sido superado!',
  CustomerSatisfaction: 'Satisfacción del cliente',
  CustomerSatisfactionSurveys: 'Encuestas de satisfacción al cliente',
  CustomerService: 'Servicio al cliente',
  CustomerTag: 'Etiqueta cliente',
  Daily: 'Diario|Diarios',
  DailyReport: 'Informe Diario',
  DailyVoucher: 'Comprobante Diario|Comprobantes Diarios',
  Dashboard: 'Tablero|Tableros',
  Data: 'Dato|Datos',
  Date: 'Fecha|Fechas',
  DateFilter: 'Filtro de fecha',
  DateManufacture: 'Fecha de manufactura',
  DateOfIssue: 'Fecha de expedición',
  DateOfPurchase: 'Fecha de compra',
  Day: 'Día|Días',
  DayWarranty: 'Día de garantía | Días de garantía',
  DayWarrantyNumber: 'Los días de garantía deben ser números',
  Deactivate: 'Desactivar',
  DeactivateCommission: '¡RECUERDA! Si deseas una comisión nueva, sin afectar las facturas ya creadas debes desactivar la comisión y crear una nueva.',
  Deal: 'Negocio|Negocios',
  DealClseDate: 'Fecha de cierre del negocio',
  DealDetails: 'Detalles del negocio',
  DealInformation: 'Información del negocio',
  DealName: 'Nombre del negocio',
  Debit: 'Débito|Débitos',
  DebitNote: 'Nota débito | Notas débitos',
  DebitNotePartial: 'Con nota débito Parcial',
  December: 'Diciembre',
  Deducted: 'Deducido|Deducidos',
  Deduction: 'Deducción',
  DefaultPriceList: 'Lista de precios por defecto',
  DefaultValue: 'Valor predeterminado | Valores predeterminados',
  Delete: 'Eliminar',
  DeleteConfirmation: '¿Realmente quieres eliminar el registro?|¿Realmente quieres eliminar los registros?',
  Deleted: 'Eliminado|Eliminada',
  Delivered: 'Entregado',
  Delivery: 'Entrega',
  DeliveryAddress: 'Dirección de entrega',
  DeliveryData: 'Datos de entrega',
  DeliveryStage: 'Etapa de entrega|Etapas de entregas',
  DeliveryStatus: 'Estado de entrega',
  Department: 'Departamento',
  DepreciationAccount: 'Cuenta depreciación',
  DepreciationExpenseAccount: 'Cuenta gastos de depreciacion',
  DepreciationForAccount: 'Cuenta para depreciación',
  Description: 'Descripción|Descripciones',
  descriptionPlaceholder: 'Escriba una descripción',
  DeskKind: 'Tipo de solicitud | Tipos de solicitudes',
  Detail: 'Detalles',
  DeterminesOrderCancel: 'Determina que la orden de producción fue cancelada',
  DeterminesOrderFinalized: 'Determina que la orden de producción ya fue finalizada',
  Dian: 'DIAN',
  DianNotAvailable: 'DIAN no disponible',
  DigitalSignature: 'Firma digital | Firmas digitales',
  DirectCost: 'Costo directo | Costos directos',
  DirectorateLabor: 'Dirección trabajo',
  Disabled: 'Desactivado',
  Discount: 'Descuento | Descuentos',
  DiscountAffectProduct: 'Si desea otorgar un descuento y afecte la base del impuesto hagalo por producto',
  DiscountAppliedInvoice: 'Descuentos aplicados a nivel de factura, no afecta la base de los impuestos',
  DiscountAppliedInvoiceLevel: 'Este es un descuento aplicado a nivel de factura, no afecta la base de los impuestos calculados a nivel de detalle o de cada producto',
  DispatchOrder: 'Órden de despacho|Ordenes de despacho',
  Document: 'Documento|Documentos',
  Documentation: 'Documentación',
  DocumentConsecutiveCancelled: 'El documento con consecutivo {value} ya está anulado',
  DocumentConsecutiveNotCancelled: 'El documento con consecutivo {value} NO está anulado',
  DocumentGreaterOrEqual: '¡El total del documento debe ser mayor o igual a cero(0)!',
  DocumentIsTest: 'El documento esta en el ambiente de pruebas de la DIAN, este documento no tiene validez para deducción de gastos.',
  DocumentJournalRenumber: 'Renumerar documento contable',
  DocumentNotConvertToInvoice: 'El documento: {consecutive}, no se puede convertir a factura con otros documentos, por que contiene anticipos | Este documento no se puede convertir a factura',
  DocumentNumber: 'Número documento',
  DocumentPendingTransmitted: 'Documentos pendientes por transmitir a la DIAN',
  DocumentsTransmittedInvalid: 'Documentos transmitidos ante la DIAN inválidados',
  DocumentsTransmittedValidated: 'Documentos transmitidos ante la DIAN validados',
  DocumentSuccessfullySentTo: 'Documento enviado exitosamente a',
  DocumentTitle: 'Título del documento',
  DocumentToReplace: 'Documento a Reemplazar',
  DocumentType: 'Tipo de Documento|Tipos de Documentos',
  DocumentTypeNotDeleteHasRecords: 'Este tipo de comprobante está en uso, no se permite eliminarlo. Tiene asientos contables emitidos',
  DoesNotContainAttachedFiles: 'No contiene archivos adjuntos',
  Dormant: 'Inactivo|Inactivos',
  Download: 'Descargar | Descargar {0}',
  DownloadedSuccessfully: '¡Descargado exitosamente!',
  DownloadPdf: 'Descargar PDF',
  DropOrClickUpload: 'Arrastra tu archivo aquí o da click para seleccionarlo',
  DueDiligence: 'Debida diligencia',
  Duplicate: 'Duplicar',
  DuplicateAccountingDocumentTemplate: 'Duplicar Plantilla de documento contable',
  DuplicateAccountingEntry: 'Duplicar asiento contable',
  DuplicateExpense: 'Duplicar gasto | Duplicar gastos',
  DuplicatePurchaseOrder: 'Duplicar orden de compra | Duplicar Ordenes de compra',
  DuplicatePurchaseRemission: 'Duplicar Remisión de Compra | Duplicar remisiones de compras',
  DuplicateQuote: 'Duplicar cotización | Duplicar cotizaciones',
  DuplicateSaleInvoice: 'Duplicar factura de venta',
  DuplicateSaleOrder: 'Duplicar orden de venta| Duplicar Ordenes de venta',
  DuplicateSaleRemission: 'Duplicar Remisión de venta',
  DuplicateSerial: 'Serial duplicado | Seriales duplicados',
  DV: 'DV',
  EasyEntryTemplate: 'Plantilla de ingreso fácil',
  Edit: 'Editar',
  EditAccountingDocumentTemplate: 'Editar plantilla de documento contable',
  EditAccountingEntry: 'Editar asiento contable',
  EditActivity: 'Editar actividad',
  EditAdjustmentDebitNote: 'Editar Nota de ajuste (débito)',
  EditAttribute: 'Editar atributo',
  EditBankingConcept: 'Editar concepto bancario',
  EditBranch: 'Editar sucursal',
  EditBrand: 'Editar marca',
  EditCard: 'Editar tarjeta | Editar tarjetas',
  EditCategory: 'Editar categoría',
  EditCommission: 'Editar comisión|Editar comisiones',
  EditCompany: 'Editar compañía',
  EditContact: 'Editar contacto',
  EditCreditNote: 'Editar Nota crédito en venta',
  EditDeal: 'Editar negocio',
  EditDispatchOrder: 'Editar órden de despacho',
  Edited: 'Editado',
  EditEmailNotification: 'Editar notificación de correo electrónico',
  EditEmployee: 'Editar Empleado | Editar Empleados',
  EditExpense: 'Editar gasto | Editar gastos',
  EditFixedAssetCategory: 'Editar categoría de activo fijo',
  EditInventory: 'Editar inventario',
  EditInventoryTransfer: 'Editar Traslado de inventario',
  EditNoteReplacement: 'Editar nota de ajuste reemplazo',
  EditPayroll: 'Editar nómina',
  EditPortal: 'Editar portal | Editar portales',
  EditProduct: 'Editar producto',
  EditProductionOrder: 'Editar orden de producción',
  EditPurchaseInvoice: 'Editar factura de compra',
  EditPurchaseDocument: 'Editar documento de compra',
  EditPurchaseOrder: 'Editar orden de compra',
  EditQuote: 'Editar cotización | Editar cotizaciones',
  EditRecurrenceInvoice: 'Editar factura recurrente',
  EditRol: 'Editar rol | Editar roles',
  EditSaleDebitNote: 'Editar Nota débito en venta',
  EditSaleInvoice: 'Editar factura de venta',
  EditSaleOrder: 'Editar orden de venta',
  EditSaleRemission: 'Editar Remisión de venta',
  EditShortcut: 'Editar acceso rápido',
  EditSurvey: 'Editar encuesta',
  EditTax: 'Editar impuesto',
  EditWithholding: 'Editar retención',
  EditTemplate: 'Editar Plantilla | Editar Plantillas',
  EditTicket: 'Editar ticket',
  EditUser: 'Editar Usuario | Editar Usuarios',
  ElectronicInvoice: 'Factura electrónica de venta',
  ElectronicPayrollConfiguration: 'Configuración Nómina Electrónica',
  ElectronicSaleInvoice: 'Factura de venta electrónica',
  Email: 'E-mail|E-mails',
  EmailAgent: 'Correo electrónico del agente | Correos electrónicos de los agentes',
  EMailNotExist: 'El  e-mail no existe',
  EmailNotification: 'Notificación por correo electrónico | Notificaciones por correo electrónico',
  EMailPreviouslyRejected: 'El e-mail ha sido rechazado previamente, ver mensajes anteriores',
  EmailSender: 'Remitentes de E-mail',
  EmailSendingLog: 'Log de envío de e-mail | Log de envíos de e-mail',
  EMailSendingLog: 'Log de envio de e-mails',
  EmailSentSuccessfully: 'E-mail enviado exitosamente | E-mails enviados exitosamente',
  EmailTemplate: 'Plantilla de E-mail|Plantillas de E-mail',
  Emitter: 'Emisor | Emisores',
  Employee: 'Empleado | Empleados',
  EmployeeCode: 'Código empleado',
  EmployeePayAccountData: 'Datos cuenta pago a empleado',
  EmployeesCurrentlyOnPayroll: 'Empleados actualmente en nomina',
  EmployeesHadContract: 'Empleados que tienen o han tenido contrato',
  Enable: 'Activar|Habilitar',
  EndDate: 'Fecha final | Fecha de finalización',
  EndHour: 'Hora de finalización',
  EnterAddress: '¡Ingrese la dirección! | ¡Ingrese las direcciones!',
  EnterAssetName: 'Ingresa el nombre del activo',
  EnterBatch: 'Ingresa un lote',
  EnterDate: '¡Ingresa una fecha!',
  EnterDayWarranty: '¡Ingresa días de garantía!',
  EnterName: '¡Ingrese el nombre! | ¡Ingrese los nombres!',
  EnterNumberInvoicesReceivePerMonth: 'Ingresa el número de facturas electronicas que realizas al mes',
  EnterPassword: 'Ingrese la contraseña',
  EnterReasonReject: 'Ingrese el motivo de rechazo',
  EnterTotalLetter: 'Ingresar total en letras',
  EnterTRM: 'Ingresar TRM',
  EnterYourCellPhone: 'Ingresa tu número de celular',
  EntryDate: 'Fecha ingreso',
  EntryWarehouse: 'Bodega de entrada',
  Environment: 'Ambiente',
  Eraser: 'Borrador',
  Error: {
    401: 'No se elimino ningun registro, lo más probable es que la cuenta esta siendo utilizada!',
    402: '¡UPS, ya existe un registro con los mismos datos!',
    403: '¡UPS, La resolución ya está configurada en otra sucursal!',
    404: '¡UPS, La resolución no exíste!',
    405: 'El consecutivo inicial no está en el rango de la resolución',
    406: 'Ingresa un descuento mayor a cero!',
    407: 'Seleccione un producto!',
    408: 'Seleccione una categoría!',
    409: 'Seleccione una marca!',
    410: 'El archivo puede estar dañado, vacío o contener una imagen PNG con una dimensión superior a 65.535 píxeles.',
    411: 'El item no contiene ninguna variante!',
    412: 'Seleccione la cuenta de comisión',
    413: 'No hay items agregados!',
    414: 'El número de meses a depreciar debe ser superior a cero (0)',
    415: 'La depreciación acumulada debe ser superior a cero (0) si existe alguna depreciación anterior',
    416: 'El producto no se puede eliminar, por que esta siendo utilizado en otros módulos',
    417: 'El activo fijo no se puede eliminar, por que esta siendo utilizado en otros modulos',
    418: 'No has seleccionado ningún archivo',
    419: '!Solo puedes importar 5 archivos máximo por día!',
    420: 'El número de registros a subir supera el limite máximo permitido de 2000!',
    421: '¡El archivo cargado no contiene datos!',
    423: '¡No se puede eliminar ningún registro seleccionado, porque está(n) siendo usado(s) en otro módulos!',
    424: 'Ingrese los item a producir!',
    425: 'No hay movimientos(registros)!',
    426: 'Selecciona el tercero!',
    427: '¡El periodo se encuentra bloqueado!',
    428: '¡El periodo no está creado!',
    429: 'La orden de producción debe estar aprobada!',
    430: 'Configurar el tipo de documento para ordenes de producción! Configuración > Comprobantes por defecto.',
    431: 'El tipo de documento no existe!',
    432: 'Realizar el cierre de todas las etapas de producción!',
    433: 'Realizar el cierre de todas las cantidades de producción!',
    434: 'El anticipo debe ser mayor a cero (0)!',
    435: 'Verifique que las cuentas de la configuración existan!',
    436: 'Verifique que las cuentas de la configuración sean de nivel detalle!',
    437: 'Selecciona un remitente valido!',
    438: '¡Selecciona al menos un registro!',
    439: '¡No hay movimientos(registros) en el documento!',
    440: '¡Configura tu tercero : Configuración > Configuración general!',
    441: '¡Ya existe la variante!',
    442: '¡Oops, No se puede modificar el registro, el registro está en uso en otros módulos!',
    443: '¡Oops, Dirección de E-mail no válida!',
    444: ' ¡UPS, el documento no puede ser anulado, está en uso en otros módulos!',
    445: '¡Hay diferencia en el documento!',
    446: '¡El documento no puede ser modificado, debe estar en estado activo!',
    447: '¡El documento a editar no pertenece a esta compañía!',
    448: '¡Verificar que los años anteriores estén ya cerrados!',
    449: '¡El débito y el crédito no son iguales, por favor verifique!',
    450: '¡El cierre ya está en proceso!',
    451: '¡El año contable no ha sido cerrado!',
    452: '¡No se puede realizar la cancelación del cierre contable ya que existen un cierre posterior!',
    453: '¡La cancelación del cierre no puedo llevarse a cabo por que existen más de un documento del tipo (CC)Cierre Contable o (SC)Saldo de Cierre!',
    454: '¡Verificar que la cuenta contable del banco exista!',
    455: '¡Verificar que la cuenta contable del banco sea de nivel detalle!',
    456: '¡Hay registros con diferente año contable, por favor guardar solo lo de un año!',
    457: '¡Verificar el tercero del banco o mi compañía exista!',
    458: '¡El periodo está cerrado!',
    459: '¡Verifica que las cuentas de la configuración existan o sea de nivel detalle!',
    460: '¡No puede vincular el pago al mismo asiento contable!',
    461: '¡El archivo cargado contiene errores!',
    462: '¡El número de registros a subir supera el limite maximo permitido de 500!',
    463: '¡No hay documentos para enviar!',
    464: '¡La plantilla de correo y el remitente son obligatorios para enviar correos!',
    465: '¡Ingresa los productos o servicios!',
    466: '¡Selecciona el cliente!',
    467: '¡El periodo de facturación no pueden tener la misma fecha (inicio - final)!',
    468: '¡Ya has alcanzado el limite del paquete, por favor adquiere uno nuevo paquete!',
    469: '¡No tienes acceso a este servicio, por favor adquiere un paquete!',
    470: '¡Tu paquete ya venció, por favor adquiere un nuevo paquete!',
    471: '¡No tienes un paquete habilitado para facturar, por favor adquiere un nuevo paquete!',
    472: '¡Ya has alcanzado el limite del plan, comunicate con nuestra area comercial para tramitar el cambio a un plan más grande!',
    473: '¡Tu plan no tiene este módulo, comunicate con nuestra area comercial para tramitar el cambio a un plan más grande!',
    474: '¡No puede realizar notas créditos o debitos a facturas no entregadas!',
    475: '¡Selecciona el concepto!',
    476: '¡Selecciona el vendedor!',
    477: '¡La firma electrónica venció!',
    478: '¡Configurar el tipo de documento comercial en : "Configuración -> Documentos comerciales"!',
    479: '¡Selecciona el banco/caja!',
    480: '¡La cuenta configurada de anticipo no es válida!',
    481: '¡El tipo de documento de la devolución no existe!',
    482: '¡El ID no es válido!',
    483: '¡La nota crédito no existe!',
    484: '¡La nota crédito no esta aprobada, por este motivo no puede ser contabilizada!',
    485: '¡La nota crédito no es de producción, por tal motivo no contiene contabilidad!',
    486: '¡Este proceso no es válido!',
    487: '¡No hay documentos para modificar!',
    488: '¡No se permite crear la nota débito en la empresa 1394!',
    489: '¡La nota débito no existe!',
    490: 'Tus datos de ingreso no son válidos',
    491: 'Para ingresar debes confirmar tu e-mail, revisa tu buzón y sigue las instrucciones',
    492: 'Esta cuenta se encuentra bloqueada por superar los intentos fallidos, puedes intentar nuevamente en 2 minutos',
    493: 'La solicitud ya expiró, solicita nuevamente el enlace para asignar una nueva contraseña',
    494: 'El token de restauración no es válido, haga una nueva solicitud de restauración',
    495: 'La contraseña actual es inválida',
    496: 'La nota débito no esta aprobada, por este motivo no puede ser contabilizada',
    497: '¡Ingresa las facturas a despachar!',
    498: '¡Selecciona el transportista!',
    499: '¡Ingresa los productos o servicios a facturar!',
    500: '¡Selecciona el termino de comercio internacional!',
    501: '¡El pago es Inválido, no hay registros a pagar o el saldo es negativo!',
    502: '¡Configurar la resolución del documento comercial en : "Configuración -> Documentos Comerciales"!',
    503: '¡La fecha de validación de la resolución es incorrecta!"',
    504: '¡El valor de tu factura no puede ser mayor a 5 millones,  por favor adquiere uno de nuestros paquetes!"',
    505: '¡El valor facturado en el mes no puede ser mayor a 5 millones,  por favor adquiere uno de nuestros paquetes!"',
    506: '¡El evento de "Acuse de recibo" no se puede llevar acabo por que la factura no está en estado de "Aprobada"!',
    507: '¡El evento de "Reclamo" no se puede llevar acabo por que la factura no está en estado de "Recibo de bienes/servicio"!',
    508: '¡El evento de "Recibo de bienes/servicio" no se puede llevar acabo por que la factura no está en estado de "Acuse de recibo"!',
    509: '¡El evento de "Aceptación expresa" no se puede llevar acabo por que la factura no está en estado de "Recibo de bienes/servicio"!',
    510: '¡El evento de "Aceptación tácita" no se puede llevar acabo por que la factura no está en estado de "Recibo de bienes/servicio"!',
    511: '¡El evento no es válido ante la DIAN!',
    512: '¡El evento no tiene el mismo número de identificación de tu cliente de la factura!',
    513: '¡La factura de este evento no existe!',
    514: '¡El evento no tiene el mismo número de identificación de tu empresa!',
    515: '¡Token Inválido!',
    516: '¡Debes realizar el proceso de habilitación de firmar electrónicamente con Aliaddo!',
    517: '¡El tipo de documento de entrega no existe!',
    518: '¡La factura ya fue entregada, por favor verificar!',
    519: '¡Ingresa el motivo del rechazo!',
    520: '¡No hay registros válidos para actualizar!',
    521: '¡Configura la bodega del cliente/contacto!',
    522: '¡Selecciona una bodega diferente a la del cliente/contacto!',
    523: '¡Advertencia: el documento comercial no tiene documento contable relacionado, por favor edita el documento para que el sistema genere un documento contable e intenta anularla nuevamente!',
    524: '¡Los documentos seleccionados no pueden ser modificados, por que se está usando en otros módulos!',
    525: '¡La factura no existe!',
    526: '¡La factura no está aprobada, por este motivo no puede ser contabilizada!',
    527: '¡La factura no es de producción, por tal motivo no contiene contabilidad!',
    528: '¡Selecciona un consecutivo válido!',
    529: '¡Selecciona un tercero válido!',
    530: '¡No puede realizar nota crédito a facturas no entregadas!',
    531: '¡Selecciona el proveedor!',
    532: 'Ya has alcanzado el limite del paquete o vencio, comunicate con nuestra area comercial para tramitar un nuevo paquete',
    533: 'La nota de ajuste(debito) no existe',
    534: 'La nota de ajuste(debito) no esta aprobada',
    535: 'La nota de ajuste(debito) no es de producción, por tal motivo no contiene contabilidad',
    536: 'Verifica la configuración de la sucursal y documentos comerciales',
    537: 'Debes selecionar una resolución para el documento soporte',
    538: 'Ya has alcanzado el limite del plan, comunicate con nuestra area comercial para tramitar el cambio a un plan más grande',
    539: 'La factura no tiene el mismo número de identificación de tu empresa',
    540: 'El documento no es una factura electronica',
    541: 'El email no contenia una factura',
    542: 'Las facturas que ya superaron los tres (3) días hábiles después del evento de recepción del bien o servicio no se les puede emitir evento de aceptación expresa.',
    543: 'Reclamo invalido, por favor contactarse con el adminstrador!',
    544: 'No se puede crear la factura, ya que algunos registros no contienen codigo o nombre del producto',
    545: 'No se puede crear la factura, ya que algunos registros no contienen el lote asignado',
    546: 'El impuesto calculado fue eliminado, por favor verificar!',
    547: 'Configura la bodega del proveedor/contacto',
    548: 'Selecciona una bodega diferente a la del proveedor/contacto',
    549: 'Uno de los conceptos de gasto no tiene centro de costo!',
    550: 'El gasto ya está pago',
    551: '¡El gasto está en estado devolución!',
    552: 'El impuesto calculado ya no existe, por favor editar el gasto para actualizar los impuestos!',
    553: 'El nombre de usuario no esta disponible, por favor ingresar otro',
    554: 'Usuario no válido',
    555: 'Id no existente',
    556: 'El correo no ha cambiado',
    557: 'El correo ya existe en otro usuario',
    558: '¡La plantilla debe contener al menos una configuración!',
    559: '¡La cuenta ya existe en la configuración!',
    560: '¡Cuenta inválida!',
    561: '¡Cuenta mayor no existe!',
    562: '¡La acción no es válida!',
    563: 'La caja no exíste',
    564: 'La caja no está abierta, debes abrirla primero',
    565: 'La cuenta contable para pagar gastos no exíste',
    566: 'La cuenta contable para pagar gastos no ha sido configurada',
    567: 'El tipo de asiento contable no exíste',
    568: 'El anticipo no exíste',
    569: 'El anticipo no se puede editar porque proviene de una nota crédito',
    570: 'El anticipo no se puede editar porque ya hace parte de un cierre de caja',
    571: 'El anticipo no se puede editar porque ya ha sido redimido',
    572: 'El anticipo no se puede editar porque tiene devoluciones',
    573: 'El anticipo no se puede editar porque ha sido anulado',
    574: 'Tu plan no tiene este módulo, comunicate con nuestra area comercial para tramitar el cambio a un plan más grande',
    575: 'La sucursal no exíste',
    576: 'El centro de costos es obligatirio',
    577: 'La bodega es obligatiria',
    578: 'No hay conceptos (artículos) para facturar',
    579: 'El pago de la factura debe ser mayor a 0 (cero)',
    580: 'No hay conceptos (artículos) para devolver',
    581: 'La nota crédito no exíste',
    582: 'Las notas crédito electrónicas válidas no se pueden modificar',
    583: 'No hay conceptos (productos) para devolución',
    584: 'El pago de la devolución debe ser mayor a 0 (cero)',
    585: 'La factura no exíste',
    586: 'Las facturas electrónicas válidas no se pueden modificar',
    587: 'Esta factura ya fue cerrada, no se puede modificar',
    588: 'Esta factura ya fue pagada, no se puede modificar',
    589: 'Esta factura ya fue devuelta, no se puede modificar',
    590: 'Esta factura no se puede modificar',
    591: 'Las facturas apartadas no se pagan, continúa la venta primero',
    592: 'Al hacer devolución de dinero el medio de pago debe ser especificado',
    593: 'El medio de pago especificado no fue encontrado',
    594: 'El pedido no exíste',
    595: 'La compañía no existe',
    596: '¡El valor a pagar o saldo debe ser mayor a cero!',
    597: 'La cuenta de banco o caja no válida, por favor selecciona una cuenta válida',
    598: 'La cuenta de banco o caja no existe, por favor selecciona una cuenta válida',
    599: 'Solo puedes crear terceros a tu nombre, comunicate con el administrador',
    600: 'No tienes permiso para actualizar este tercero, comunicate con el administrador',
    601: 'No tienes permiso para asignar otro propietario a este tercero, comunicate con el administrador',
    602: '¡El tercero no se puede eliminar, está siendo utilizado por otros módulos!',
    603: 'Oops! Se presento un error al intentar enviar el correo, por favor intenta nuevamente',
    604: 'La dirección del tercero no se puede eliminar, está siendo utilizado por otros módulos!',
    605: '¡No se ha enviado correctamente el objeto (!)...',
    606: '¡No se ha enviado correctamente el Id (!)...',
    607: 'El documento no puede ser modificado',
    608: 'El periodo contable esta cerrado o bloqueado!',
    609: '¡Los documentos seleccionados no pueden ser anulados, por que se está usando en otros módulos!',
    610: '¡El valor a pagar debe ser mayor a cero!',
    611: '¡El valor debe ser menor al saldo!',
    612: 'Código no válido, por favor verificar',
    613: 'Ingreso no autorizado!',
    614: 'Debes asignar a los cargos la cuenta contable',
    615: 'No se encontró un archivo para subir',
    616: 'No se encontraron los datos suficientes para subir el archivo',
    617: 'No se pudo subir el archivo, intenta nuevamente',
    618: 'El archivo local no existe',
    619: 'El código postal no es válido',
    620: 'Tu plan no incluye esta característica',
    621: 'Ya existe una comisión activa, con el tipo, grupo y tercero seleccionado',
    622: 'Debes subir un archivo',
    623: 'El documento no es válido',
    624: 'Información no válida',
    625: 'Cargar el archivo de la firma',
    626: 'No has seleccionado los 4 archivos adjuntos',
    627: 'El centro de costo padre no puede contener más de 40 centros de costos relacionados',
    628: '¡Error en el reporte, no hay información!',
    629: '¡No puedes exportar el documento electrónico porque aún no es válido por la DIAN, por favor espera hasta que el documento esté validado por la DIAN!',
    630: 'Error en el reporte, por favor verifica con el administrador',
    631: 'Selecciona todas las variables',
    632: 'Empresa no existente',
    633: 'Usuario no existente',
    634: 'No se encontró notificación',
    635: 'La unidad de medida de uno de los ítems no exíste, por favor corrige',
    636: 'No se pudo eliminar ningún registro',
    637: 'Ingresa el Identificador del set de pruebas (TestSetId)',
    638: 'El Identificador del set de pruebas (TestSetId) es inválido por favor verificar, debe contener 36 caracteres',
    639: 'Selecciona la sucursal para realizar el Set de prueba',
    640: 'El rol no es válido',
    641: 'No se puede eliminar el rol, primero debes quitar este rol a los usuarios que lo tengan asignados',
    642: 'El impuesto no se puede eliminar, está siendo utilizado por otros módulos',
    643: 'La configuración ampliada no existe',
    644: 'Debes seleccionar una caja para efectuar el cierre',
    InvalidSubscription: '¡Suscripción inválida, por favor comunícate con soporte!',
    NoInternetConnection: 'No tienes conexión a internet',
    PlanError: '¡Tu plan no tiene este módulo, comunícate con nuestra área comercial para tramitar el cambio a un plan más grande!',
    SelectCompany: '¡Selecciona una empresa!',
    645: 'Ha ocurrido un error inesperado, por favor comunícate con soporte',
    646: '¡Cuenta por pagar o cobrar no valida!',
    647: 'Codigo inválido',
    648: 'No se pudo crear, no hay datos',
    649: 'No se pudo modificar, no hay datos',
    650: 'No se pudo pagar, no hay datos de la factura',
    651: 'No se puede agregar el inventario, el periodo se encuentra bloqueado',
    652: 'No se puede agregar el inventario, El periodo no esta creado',
    653: '¡La plantilla e-mail no se puede eliminar, está siendo utilizada por otros módulos!',
    654: 'Esta contraseña ha sido usada con anterioridad. Por favor, ingresa una contraseña nueva.!',
    655: 'No tienes permiso para realizar esta acción, comunícate con el administrador de la cuenta en tu empresa',
    656: 'El precio de venta / margen de utilidad (%) debe ser un dato de tipo numérico',
    657: 'El precio de compra debe ser un dato de tipo numérico',
    658: 'El peso debe ser un dato de tipo numérico',
    659: 'El embalaje (Existencia por caja o paquete) debe ser un dato de tipo numérico',
    660: 'El largo debe ser un dato de tipo numérico',
    661: 'El ancho debe ser un dato de tipo numérico',
    662: 'El alto debe ser un dato de tipo numérico',
    663: 'La cantidad minima debe ser un dato de tipo numérico',
    664: 'La cantidad de re-orden debe ser un dato de tipo numérico',
    665: 'La comision debe ser un dato de tipo numérico',
    666: 'La cantidad de componentes debe ser un dato de tipo numérico o ser mayor a 0',
    667: 'La cuenta contable de gasto debe ser un dato de tipo numérico',
    668: 'La cuenta contable de ingreso debe ser un dato de tipo numérico',
    669: 'La cuenta contable de inventario debe ser un dato de tipo numérico',
    670: 'La cuenta contable de devolucion debe ser un dato de tipo numérico',
    671: 'No se puede crear la factura, ya que algunos productos no contienen la unida de medida',
    672: 'En la columna codigo del producto existen elementos duplicados',
    673: 'La retención no se puede eliminar, está siendo utilizado por otros módulos',
    674: 'En la columna identificación existen elementos duplicados',
    675: 'No se pudo notificar al tercero. Por favor, añada un correo electrónico válido',
    676: 'Solo puedes crear negocios a tu nombre, comunícate con el administrador',
    677: 'Solo puedes crear actividades a tu nombre, comunícate con el administrador',
    678: 'No tienes permiso para actualizar esta actividad, comunícate con el administrador',
    679: 'No tienes permiso para asignar otro propietario a esta actividad, comunícate con el administrador',
    680: 'No tienes permiso para actualizar este negocio, comunícate con el administrador',
    681: 'No tienes permiso para asignar otro propietario a este negocio, comunícate con el administrador',
    682: 'No tienes permisos para eliminar actividades, comunícate con el administrador',
    683: 'Solo puedes eliminar actividades a tu nombre, comunicate con el administrador',
    684: 'No tienes permisos para eliminar negocios, comunícate con el administrador',
    685: 'Solo puedes eliminar negocios a tu nombre, comunicate con el administrador',
    686: 'Para establecer el precio del producto por margen de utilidad debes agregar una "x"',
    687: 'Para establecer si el producto es para venta debes agregar una "x"',
    688: 'Para establecer el producto es para la compra debes agregar una "x"',
    689: 'Para establecer que el producto es materia prima debes agregar una "x"',
    690: 'Para establecer que el producto maneja lotes debes agregar una "x"',
    691: 'Para establecer que el producto maneja seriales debes agregar una "x"',
    692: 'Esta cuenta ha sido bloqueada por minutos después de varios intentos fallidos. Espere 10 minutos antes de reintentarlo de nuevo. Si has olvidado la contraseña utiliza la opción "Recordar contraseña" antes de ponerse en contacto con el equipo de soporte',
    693: 'El proceso de verificación de "Soy Humano" falló, intenta nuevamente'
  },
  ErrorBranchPermission: 'No se encontraron sucursales activas o no tienes permiso, comunícate con tu administrador',
  ErrorConvertDocumentToInvoice: 'El documento no se puede convertir a factura | El documento {name} no se puede convertir a factura, por que ya contiene una factura o esta anulada!',
  ErrorConvertToPurchaseRemission: 'El documento: {consecutive}, no se puede convertir a "Remisión de compra"',
  ErrorConvertToSaleRemission: 'El documento: {consecutive}, no se puede convertir a "Remisión de venta"',
  ErrorDocumentConceptRemission: 'El documento {name} no pertenece al mismo concepto de remisión',
  ErrorDocumentPerson: 'El documento {name} no pertenece al mismo tercero',
  ErrorDocumentStatus: 'Los documentos seleccionados no se pueden cambiar al estado {statuName}',
  ErrorEnterExchangeRate: '¡Ingresa la tasa de cambio!',
  ErrorSelectSenderEmailTemplate: '¡Selecciona un remitente y una plantilla de correo!',
  Event: 'Evento',
  ExampleSizeColor: 'Ejemplo: TALLA, COLOR',
  ExampleTooltipVariant: 'Esto se añade al código y nombre del artículo de la variante. Por ejemplo, si su abreviatura es "Negro" y el código del artículo es "ITEM-001" , el código del artículo de la variante será "ITEM-001-Negro"',
  ExchangeRate: 'Tasa de cambio | Tasas de cambios',
  ExcludePerson: 'Excluir tercero',
  ExcludePersonHelper: 'Selecciona el tercero a excluir en el reporte, por ejemplo, (CO): "Dirección De Impuestos Y Aduanas Nacionales Dian"',
  ExcludePersonTooltip: 'Al seleccionar el tercero, todos los movimientos contables de dicho tercero no se tendrán en cuenta al generar el reporte.',
  ExistCommissionPerson: 'Si existe una comisíón por el tercero tendrá más prioridad que las comisiones "GENERAL"',
  Existence: 'Existencia',
  Exit: 'Salir',
  ExogenousInfoByAccount: 'Información Exógena por Cuenta',
  ExogenousInfoByFormat: 'Información Exógena por Formato',
  ExogenousInformationFormat: 'Formatos de información exógena',
  ExogenousInformation: 'Información exógena',
  ExpectedCloseDate: 'Fecha de cierre prevista',
  Expense: 'Gasto|Gastos',
  ExpenseSuccessfullyCanceled: 'anulación de gastos cancelada exitosamente',
  ExpenseSuccessfullyReversed: 'Gastos anulados con éxito',
  ExpenseType: 'Tipo de gasto | Tipos de gastos',
  Expiration: 'Vencimiento',
  ExpirationDate: 'Fecha de vencimiento',
  ExpirationDateGreaterDateDocument: '¡La fecha de vencimiento debe ser mayor a la fecha del documento!',
  ExpirationDateLowerManufacture: 'Fecha vencimiento inferior a fecha manufactura',
  Expired: 'Vencido | Vencidos | Vencida | Vencidas',
  Export: 'Exportar',
  ExportBlockPdf: 'Exportar PDF - Bloque',
  ExportBy: 'Exportar por',
  ExportDetailedExcel: 'Exportar Excel Detallado',
  ExportExcel: 'Exportar Excel',
  ExportExcelByInvoicingFlow: 'Exportar excel con el flujo de facturación',
  ExportExcelByMonths: 'Exportar a Excel por Meses',
  ExportExcelByWarehouse: 'Exportar Excel por Bodegas',
  ExportPdf: 'Exportar a PDF',
  ExportPdfByBatch: 'Exportar a PDF por Lote|Exportar a PDF por Lotes',
  ExportReport: 'Exportar Reporte|Exportar Reportes',
  FAQ: 'FAQ',
  FastAnswer: 'Repuesta Rápida',
  February: 'Febrero',
  FeConfiguration: 'Configuración FE',
  Feedback: 'Sugerir mejoras',
  FieldRequired: 'Este campo es requerido',
  FileUploaded: 'Archivo cargado|Archivos cargados',
  Filter: 'Filtro | Filtros',
  FilterCode: 'Filtrar código | Filtrar códigos',
  FilterName: 'Filtrar nombre | Filtrar nombres',
  FilterRegion: 'Filtrar región | Filtrar regiones',
  FinalRange: 'Rango final',
  FinancialReporting: 'Reporte Financiero|Reportes Financieros',
  Finish: 'Terminar',
  Finished: 'Finalizado|Finalizados',
  FirstName: 'Primer nombre',
  FirstNameCompanyName: 'Primer nombre / Nombre de la empresa',
  FirstSurname: 'Primer apellido',
  FiscalPeriod: 'Periodo fiscal|Periodos fiscales',
  FiscalPeriodSuccessfullyCreated: '¡Periodo fiscal creado con éxito!',
  FixedAsset: 'Activo fijo|Activos fijos',
  FixedAssetCategory: 'Categoría de activo fijo | Categorías de activos fijos',
  FixedAssetDetailedView: 'Activo fijo detallado',
  FixedAssetsAccountCost: 'Cuenta costo del activo fijo',
  FixedValue: 'Valor Fijo | Valores Fijos',
  Footer: 'Pie de página',
  For: 'Para',
  ForgotPassword: 'Olvidé mi contraseña',
  Format: 'Formato|Formatos',
  Friday: 'Viernes',
  From: 'Desde',
  FromAccount: 'De la cuenta|De las cuentas',
  FromConsecutive: 'Desde el Consecutivo',
  FromDay: 'Desde el día',
  FromMonth: 'Desde el Mes',
  FullName: 'Nombre completo',
  FullRefund: 'Devolución total',
  Fuse: 'Fusionar',
  Gems: 'Gemas',
  General: 'General|Generales',
  GeneralBalance: 'Balance General',
  GeneralLedgerAndBalance: 'Mayor y Balance',
  GeneralSetting: 'Configuración general',
  GenerateBackup: 'Generar Copia de Respaldo',
  GeneratedResolutionBeforeAssociateThePrefix: 'Si haz generado la resolución con anterioridad, por favor asociar el prefijo o rango de numeración',
  GeneratedResolutionPreviously: 'Ya tengo una resolución de numeración.',
  GenerateNumberingResolution: 'Generar nueva resolución de numeración',
  GenerateResolutionMuisca: 'Para generar una nueva resolución de numeración debes ingresar al portal MUISCA de la DIAN en el siguiente enlace',
  GenerationDate: 'Fecha generación',
  GiftOrTradeSample: 'Es un regalo o muestra comercial',
  GoodToBad: 'De bueno a malo',
  Group: 'Grupo | Grupos ',
  GroupByPerson: 'Agrupar por tercero|Agrupar por terceros',
  Grouped: 'Agrupado|Agrupados',
  GroupedByAccount: 'Agrupado por cuenta',
  GroupedByThirdParty: 'Agrupado por tercero',
  GroupName: 'Nombre del grupo',
  HabilitationDocumentElectronic: 'Habilitación para Documentos Electrónicos de Firma Digital de Aliaddo',
  Half: 'Media',
  HandleError: 'Ha ocurrido un error inesperado, el equipo de Aliaddo ya fue informado, por favor intenta más tarde',
  HandleErrorUser: 'Opps, no se pudo llevar a cabo el proceso, por favor intenta en unos segundos',
  HasAttachment: 'Tiene archivo adjunto | Tiene archivos adjuntos',
  HasBusiness: '¿Tiene negocios?',
  HaveAssociatedPrefixClickOnButton: 'Una vez hayas asociado el prefijo o rango de numeración haz clic en el botón de abajo',
  HeardAboutUs: '¿Como te enteraste de nosotros?',
  Help: 'Ayuda | Ayudas',
  HelpHabilitationDocumentElectronic: 'Para habilitar los documentos electrónicos debes firmar el contrato de mandato el cuál puedes descargar en el link de abajo y adjuntar los siguientes documentos en formato PDF:',
  HelpPlaceHolder: 'Has clic para insertar marcadores de posición en el contenido, se resolverán dinámicamente en los datos apropiados al enviar el e-mail',
  High: 'Alta',
  HighPensionRisk: 'Alto riesgo pensión',
  Historic: 'Historial',
  Home: 'Inicio',
  HowBecomeElectronicBiller: '¿Cómo habilitarme como facturador electrónico?',
  HowConsultSettings: '¿Cómo consultar los ajustes?',
  HowGenerateReplacementAdjustment: '¿Cómo generar una nota de ajuste de reemplazo?',
  HowToImportThirdParties: '¿Cómo importar terceros?',
  Identification: 'Identificación',
  IdentificationDocument: 'Documento identificación',
  IdentificationType: 'Tipo de identificación|Tipos de identificación',
  IdentityDocumentRepresentative: 'Documento de identidad del representante legal',
  Image: 'Imagen | Imagenes',
  ImageUrl: 'Imagen (url)',
  Import: 'Importar',
  ImportAccountingEntry: 'Importar asientos contables',
  ImportAccountingEntryBalance: 'Importar asientos contables - Saldos',
  ImportBalance: 'Importar saldo|Importar saldos',
  ImportChartOfAccounts: 'Importar plan de cuentas',
  Imported: 'Importado',
  ImportInventory: 'Importar inventarios|Importar inventarios',
  ImportProductPrices: 'Importar precios de productos',
  ImportProducts: 'Importar productos',
  Inactivate: 'Inactivar',
  Inactive: 'Inactiva | Inactivas',
  IncludeCreditNotes: 'Incluir notas crédito',
  Included: 'Incluido|Incluidos',
  IncludeDebitNotes: 'Incluir notas débito',
  IncludedInPrice: 'Incluido en el precio',
  IncludeInitialBalanceQuestion: '¿Incluir saldos iniciales?',
  IncludePerson: 'Incluir tercero|Incluir terceros',
  Income: 'Ingreso | Ingresos',
  IncomeStatement: 'Estado de Resultado',
  IncomeStatementByCostCenter: 'Estado de Resultado por Centro de Costos',
  IncomeStatementByParentCostCenter: 'Estado de Resultado por Centro de Costos Padre',
  InconsistentAccountingEntry: 'Asiento Contable Descuadrado|Asientos Contables Descuadrados',
  InconsistentAccountingEntryHelper: 'Con esta consulta podrás encontrar documentos que tengan movimientos con diferencias en el débito y el crédito, Aliaddo procura que esto no suceda con todas sus validaciones, sin embargo, hay casos específicos enlos que puede suceder.',
  InconsistentDocument: 'Documento Inconsistente|Documentos Inconsistentes',
  INCOTERMS: 'INCOTERMS',
  IncreaseAccruing: 'Si esta chequeado, se va a sumar al devengado por el empleado, de lo contrario se informara en el xml pero no se suma al total del comprobante',
  InfoCancelproductionOrder: 'Se permite cancelar y al procesar valida la existencia de los materias primas del producto y realiza la respectiva salida de inventario',
  InfoEditProductionOrder: 'Se permite editar o cancelar y al aprobar valida la existencia de los materias primas del producto',
  InfoNextStepResolution: 'Para el paso 2 debes esperar 2 horas para que la resolución este disponible en el portal de FACTURACIÓN ELECTRÓNICA',
  InfoNumberingResolutionPrefixAssociation: 'Una vez generada la resolución de numeración, debes esperar 2 horas para continuar con el proceso de asociación de prefijo, para que la resolución este disponible en el portal de FACTURACIÓN ELECTRÓNICA',
  InfoOutOfStock: 'Determina que la orden de producción al aprobar o procesar no contenia las existencias requeridas de las materias primas de los productos y solo se permitira cancelar o procesar nuevamente',
  InfoRegistrationProcess: 'Al hacer el proceso de registro se cerrará la sesión y deberás ingresar nuevamente para realizar el paso 3',
  Informative: 'Informativo',
  InItem: 'En el ítem',
  InitialConsecutive: 'Consecutivo inicial',
  InitialRange: 'Rango inicial',
  INotNumberingResolution: 'No tengo resolución de numeración',
  InProcess: 'En proceso',
  InputTrade: 'Input ID (comercio)',
  Insert: 'Insertar',
  Installed: 'Instaladas',
  Integral: 'Integral',
  Invalid: 'Inválido | Inválidos',
  InvalidAdjustment: '¡Ajuste inválido! | ¡Ajustes inválidos!',
  InvalidBranch: '¡Sucursal inválida! | ¡Sucursales inválidas!',
  InvalidCostCenter: '¡Centro de costo inválido! | ¡Centros de costos inválidos!',
  InvalidCurrency: '¡Moneda inválida! | ¡Monedas inválidas!',
  InvalidCustomer: '¡Cliente inválido! | ¡Clientes inválidos!',
  InvalidDate: '¡Fecha inválida! | ¡Fechas inválidas!',
  InvalidDiscountValue: '¡Valor de descuento inválido!',
  InvalidExpirationDate: '¡Fecha de vencimiento inválida! | ¡Fechas de vencimientos inválidas!',
  InvalidItem: '¡Ítem inválido!',
  InvalidPaymentMean: '¡Medio de pago inválida!',
  InvalidQuantity: '¡Cantidad inválida! | ¡Cantidades inválidas!',
  InvalidSender: '¡Remitente inválido! | ¡Remitentes inválidos!',
  InvalidTemplate: '¡Plantilla inválida! | ¡Plantillas inválidas!',
  InvalidUnitValue: '¡Valor unitario inválido!',
  InvalidWarehouse: '¡Bodega inválida! | ¡Bodegas inválidas!',
  Inventory: 'Inventario|Inventarios',
  InventoryAlreadyDeleted: 'El movimiento de inventario ya está eliminado | Los movimientos de inventario ya están eliminados | El movimiento de inventario {consecutive} ya está eliminado ',
  InventoryAndBalance: 'Inventario y Balance',
  InventoryAndBalanceByParentCostCenter: 'Inventario y Balance por Centro de Costos Padre',
  InventoryLoss: 'Pérdida de inventario',
  InventoryMovement: 'Movimiento de inventario|Movimientos de inventario',
  InventoryToDate: 'Inventario a la fecha|Inventarios a la fecha',
  InventoryTransfer: 'Traslado de inventario',
  Invoice: 'Factura | Facturas',
  Invoiced: 'Facturado | Facturados',
  InvoiceData: 'Datos de facturación',
  Invoicing: 'Facturación',
  InvoicingAddress: 'Dirección de facturación',
  InvoicingReport: 'Reporte de facturación | Reportes de facturación',
  IsActive: '¿Está activa?',
  IsAvailable: '¿Está disponible|¿Están disponibles?',
  IsEmpty: 'Está vacío | Están vacíos',
  Item: 'Item | Items',
  itemAssociatedNotAssigned: 'Hay items que deben tener lote o seriales asociados y no los has asignado',
  ItemCode: 'Código del producto',
  ItemExceedWarehouse: '¡La cantidad de ítems ingresada supera las existencias en bodega!',
  ItemName: 'Nombre del producto',
  ItIsEnabled: '¿Está habilitado?',
  IWantPOS: '¡Quiero POS!',
  January: 'Enero',
  JournalRenumber: 'Renumerar asiento',
  July: 'Julio',
  June: 'Junio',
  Kardex: 'Kardex',
  LastActivity: 'Última actividad | Últimas actividades',
  LastActivityDate: 'Fecha de última actividad | Fecha de las últimas actividades',
  LastDay: 'Últimos {number} días',
  LastName: 'Apellido|Apellidos',
  LastPrivateComment: 'Último comentario privado | Últimos comentarios privados',
  LastPublicComment: 'Último comentario público | Últimos comentarios públicos',
  LastUser: 'Último usuario',
  Layout: 'Acceso rápido|Accesos rápidos',
  LearnMore: 'Aprende más',
  LegalRepresentative: 'Representante Legal',
  Level: 'Nivel|Niveles',
  LifeCycleStatus: 'Estado del ciclo de vida',
  Link: 'Vincular',
  LinkedPayment: 'Pago Vinculado|Pagos Vinculados',
  LinkPaymentWithAccountingEntry: 'Vincular pago con el asiento contable - Causación',
  LinkRecord: 'Vincular Registro|Vincular Registros',
  List: 'Lista',
  Loading: 'Cargando',
  Location: 'Ubicación',
  Locked: 'Bloqueado',
  LogDian: 'Log DIAN',
  LoggedInUsers: 'Usuario conectado | Usuarios conectados',
  Login: 'Entrar',
  Logo: 'Logo|Logos',
  LossReason: 'Motivo de pérdida | Motivos de pérdida',
  LowerDiscountValue: '¡El valor de descuento no debe ser inferior a 0 (cero)!',
  LowerUnitValue: '¡El valor unitario no debe ser inferior a 0 (cero)!',
  Loyalty: 'Fidelización',
  Manage: 'Administrar',
  Mandate: 'Mandato | Mandatos',
  MandateContract: 'Contrato de Mandato',
  Manually: 'Manualmente',
  Manufacture: 'Manufactura',
  March: 'Marzo',
  MarkAsDone: 'Marcar como realizada',
  Marker: 'Marcador|Marcadores',
  MassUpdate: 'Actualización Masiva',
  MaturityDateWarranty: 'Fec.Ven Garantía | Fecha vencimiento garantía',
  May: 'Mayo',
  MeasurementUnit: 'Unidad de medida|Unidades de medidas',
  Menu: 'Menú',
  Message: 'Mensaje|Mensajes',
  MessageLog: '¡RECUERDA! La trazabilidad solo dura un máximo de 90 dias',
  MessagePayrollTest: 'Esta nómina es una prueba, este documento no tiene valor fiscal  ante la DIAN',
  MessageSeeOurSpecialistsInAliaddo: 'Muy pronto podrás ver nuestros especialistas en Aliaddo',
  MinChamberCommerce: 'Cámara de Comercio menor a 30 días',
  MinExistenceCertification: 'Certificación de existencia y representación legal menor a 30 días (Cámara de comercio)',
  MinimumCharacter: 'Ingresa mínimo 4 caracteres',
  MinimumQuantityAlarm: 'Alarma de cantidad mínima|Alarma de cantidades mínimas',
  Minute: 'Minuto | Minutos',
  MissingBatch: 'Falta el lote | Faltan los lotes',
  MissingSerial: 'Falta el serial | Faltan los seriales',
  ModifiedDate: 'Fecha de modificación',
  Monday: 'Lunes',
  Month: 'Mes|Meses',
  MonthDepreciate: 'Meses a depreciar',
  Monthly: 'Mensual',
  More: 'Más',
  MoreAction: 'Más acciones',
  Move: 'Mover',
  Movement: 'Movimiento|Movimientos',
  MovementByParentCostCenter: 'Movimiento por Centro de Costos Padre|Movimientos por Centro de Costos Padre',
  MoveToFolder: 'Mover a la carpeta',
  MustUploadAFile: 'Debes subir un archivo',
  MyAccount: 'Mi cuenta | Mis cuentas',
  MyBank: 'Mi banco',
  MyCompany: 'Mi empresa | Mis empresas',
  MyData: 'Mis datos',
  MyPlan: 'Mi plan | Mis planes',
  Name: 'Nombre | Nombres',
  NameAgent: 'Nombre del agente | Nombres de los agentes',
  NameIsRequired: '¡El nombre es obligatorio! | ¡Los nombres son obligatorios!',
  NameRequired: 'Nombre requerido',
  NaturalSchedule: 'Hora natural',
  Neighborhood: 'Barrio | Barrios',
  NetPayable: 'Neto pagar | NETO A PAGAR',
  NetValue: 'Valor neto | Valores neto',
  NeverBeenOpened: 'Nunca ha sido abierta',
  New: 'Nueva|Nuevo',
  NewCompany: 'Nueva compañía',
  NewContact: 'Nuevo contacto | Nuevos contactos',
  NewDefaultResponse: 'Nueva respuesta predeterminada',
  NewGroup: 'Nuevo Grupo',
  NewPassword: 'Nueva contraseña',
  NewPortal: 'Nuevo portal | Nuevos Portales',
  NewRequest: 'Nueva solicitud',
  NewSurvey: 'Nueva encuesta',
  NewToElectronicInvoicing: 'Soy nuevo en Facturación Electrónica',
  Next: 'Siguiente',
  NextDay: 'Siguientes {number} días',
  NumberDay: '{number} días',
  NextStepAddTechnologyProvider: 'Una vez hayas seleccionado a "Aliaddo" como proveedor tecnológico haz clic en el botón de abajo',
  No: 'No',
  NoAdvanceShow: 'No hay anticipo para mostrar | No hay anticipos para mostrar',
  NoBatch: 'No hay lote | No hay lotes',
  NoPosRegister: 'No hay caja | No hay cajas',
  NoBranch: 'No hay sucursal | No hay sucursales',
  NoBrand: 'No hay marcas',
  NoChangeToSave: 'Sin cambios para guardar',
  NoCommission: 'No hay comisión | No hay comisiones',
  NoConceptShow: 'No hay concepto para mostrar | No hay conceptos para mostrar',
  NoDocumentsCreateFirst: 'No hay documentos, crear el primero',
  NoExpenseType: 'No hay tipos de gastos para mostrar',
  NoFoundEmail: 'No hay e-mail|No hay e-mails',
  NoFoundFile: 'No hay archivos',
  NoItDoesNotHave: 'No tiene',
  NoMatchesFound: 'No se encontró coincidencia | No se encontraron coincidencias',
  None: 'Ninguno | Ningunos',
  NoPdfDownload: '¡No hay PDF para descargar!',
  NoRecord: 'No hay registro|No hay registros',
  NoSerialAvailable: 'No hay serial disponible | No hay seriales disponibles',
  NoSerialCreate: 'No hay seriales para crear',
  NoSerialRelatedProduct: 'No hay seriales relacionados a este producto',
  NoShortcut: 'No hay acceso rápido | No hay accesos rápidos',
  NotAddArticle: 'No agregaste artículos, agrega almenos uno',
  NotAssignedSerialProduct: 'No has asignado seriales al producto',
  NoData: 'Sin Datos',
  NotDocument: 'No hay documento | No hay documentos',
  NotDocumentShow: 'No hay documento para mostrar | No hay documentos para mostrar',
  Note: 'Nota | Notas',
  NotEmpty: 'No está vacío | No están vacíos',
  NotEnoughStockOfTheBatch: 'No hay suficientes existencias del lote',
  NotEnteredConcept: '¡No has ingresado concepto alguno!',
  NotEnteredExpenseItem: '¡No has ingresado concepto de gasto alguno!',
  NotEnteredExpenseValue: '¡No has ingresado valor de gasto alguno!',
  NotExist: 'No existe',
  NotFoundProductionOrder: 'No se encontraron ordenes de producción',
  Notification: 'Notificación',
  NotificationEmail: 'Notificación por email',
  NotIncludedYourPlan: 'No incluido en tu plan',
  NotInstalled: 'No instaladas',
  NotPaid: 'No pagado|No pagados',
  NotPermitted: 'No tiene permiso para realizar esta acción',
  NotPermittedCompany: '¡No tienes permiso para realizar esta acción, comunícate con el administrador de la cuenta en tu empresa!',
  NotUseDocuments: 'No use más de 50 documentos a la vez, si va a usar más de 50 documentos, use 50 o menos y luego use los que le falten | ¡No use más de 50 documentos a la vez!',
  NotWanted: 'No deseado',
  November: 'Noviembre',
  NoXmlDownload: '¡No hay XML para descargar!',
  Nullified: 'Anulado',
  Number: 'Número | Números',
  NumberInvoicesReceivedPerMonth: 'Número de facturas electronicas que realizas al mes',
  NumberMonthsDepreciate: 'Número de meses a depreciar',
  NumberMonthsDepreciated: 'El número de meses a depreciar debe ser mayor a cero (0) e inferior a 540 meses',
  NumberPurchaseOrder: 'No. orden de compra | No. ordenes de compra',
  October: 'Octubre',
  Of: 'De',
  OfficialJournal: 'Diario Oficial',
  OnConsignment: 'En consignación?',
  OnlinePay: 'Pagar online',
  Opened: 'Abierto | Abierta',
  OpeningBalance: 'Saldo inicial',
  Optional: 'Opcional',
  OrderBy: 'Ordenar por',
  OrderDetailsNotFound: 'Detalles de la orden de producción no encontrados',
  OrderProcessedSuccessfully: 'Orden procesada con éxito',
  OrderSuccessfullyApproved: 'Orden aprobada con éxito',
  OrderSuccessfullyCancelled: 'Orden cancelada con éxito',
  OrderSuccessfullyCompleted: 'Orden terminada con éxito',
  Origin: 'Origen|Orígenes',
  Other: 'Otra|Otras',
  OutgoingWinery: 'Bodega de salida',
  OutOfStock: 'Sin existencias',
  OutOfWarranty: 'Fuera de garantía',
  Override: 'Anular|Anulada',
  Owner: 'Propietario|Propietarios',
  OwnerEmail: 'E-mail del propietario',
  OwnerLastName: 'Apellidos del propietario',
  OwnerName: 'Nombre del propietario',
  Paid: 'Pagado|Pagados',
  PaidValue: 'Valor pagado|Valores pagados',
  Parked: 'Apartado|Apartados',
  PartialPayment: 'Pago parcial | Pagos parciales',
  PartialRefund: 'Devolución parcial',
  Password: 'Contraseña',
  PasswordHome: 'Contraseña (Inicio)',
  PasswordRecovery: 'Recuperar contraseña',
  PasswordRecoveryMessageSentEmail: 'Te hemos enviado un e-mail para que puedas asignar una nueva contraseña, el e-mail puede tardar segundos o unos cuantos minutos en llegar.',
  PasswordSuccessfullyChanged: '¡Contraseña cambiada con éxito!',
  PasswordVerify: 'Contraseña (Verificar)',
  Pay: 'Pagar',
  PayableAccount: 'Cuenta por Pagar|Cuentas por Pagar',
  Payment: 'Pago|Pagos',
  PaymentMean: 'Medio de pago|Medios de pago',
  PaymentMeanAccountHelper: 'Agrega una forma de pago seguras en nuestro sistema y olvídate de tener que ingresar a tu banco cuando se vence tu suscripción.',
  PaymentMethod: 'Forma de Pago',
  PaymentNumber: 'Número de pago',
  PaymentReceipt: 'Recibo de Pago|Recibos de Pago|Comprobante de Pago|Comprobantes de Pago',
  PaymentReminder: 'Recordatorio de pago|Recordatorios de pago',
  PaymentReport: 'Reporte de pago | Reporte de pagos | Reportes de pago',
  Payroll: 'Nómina | Nóminas',
  PayrollLite: 'Nómina lite',
  PayrollOutsourcing: 'Outsourcing de Nómina',
  Pdf: 'PDF',
  PdfBatch: 'Lote de PDF|Lotes de PDF',
  PdfBatchHelper: 'Los lotes de pdf se ejecutarán en máximo 12 horas, tan pronto se haya ejecutado la tarea se te notificará por email.',
  Pending: 'Pendiente|Pendientes',
  PendingTransmission: 'Pendiente a transmitir',
  Percentage: 'Porcentaje | Porcentajes',
  PercentageResidualValue: 'Porcentaje valor residual',
  PerformTestSet: 'Realizar set de prueba',
  PerPage: 'Por página',
  Person: 'Tercero|Terceros',
  PersonAffectedBankReconciliation: 'El tipo de tercero que se afectará en la conciliación bancaria',
  Personalized: 'Personalizado|Personalizados',
  PersonGroup: 'Etiquetas de terceros',
  PersonInformation: 'Información del tercero',
  PersonRelated: 'Tercero relacionado',
  Phone: 'Teléfono | Teléfonos',
  PickColor: 'Selecciona un color',
  PickupDone: 'Recogido',
  Pipeline: 'Proceso|Procesos',
  PlaceholderSelect: 'Selecciona...',
  PlaceholderWriteNote: 'Escriba una nota',
  PleaseWaitFewMinutes: 'Ejecutando el set de prueba, puede tardar 5 min',
  PointOfSale: 'Punto de venta | Puntos de venta',
  PointOfSaleCashier: 'Cajero de punto de venta',
  Portal: 'Portal | Portales',
  Pos: 'POS',
  PosAdvance: 'Anticipo | Anticipos',
  PosElectronicCreditNote: 'Nota crédito de factura electrónica de venta POS | Notas crédito de facturas electrónicas de venta POS',
  PosElectronicInvoice: 'Factura electrónica de venta | Facturas electrónicas de venta',
  PosEquivalentDocument: 'POS Electrónico',
  PosEquivalentDocumentCreditNote: 'Nota de crédito en POS electrónico | Notas crédito en POS electrónico',
  PosInvoice: 'Factura POS',
  PosOrder: 'Pedido | Pedidos',
  PosSalesInvoice: 'Factura de venta POS',
  PotentialCustomer: 'Posible cliente|Posibles clientes',
  Prefix: 'Prefijo | Prefijos',
  PrefixAssociation: 'Asociación de prefijos',
  Preview: 'Vista previa | Vistas previas',
  Previous: 'Anterior',
  Price: 'Precio|Precios',
  PriceList: 'Lista de precios|Listas de precios',
  PriceProductGift: 'El precio producto debe ser mayor a 0 (cero) si no está marcado como regalo',
  PriceToUpdateNotFound: 'No se encontraron precios para actualizar',
  Print: 'Imprimir',
  PrintDetailed: 'Imprimir detallado',
  Prioritie: 'priodidad | proridades',
  Priority: 'Prioridad',
  Privilege: 'Privilegio | Privilegios',
  PrizeAcquired: '¡Premio adquirido!',
  PrizeWasNotPurchased: '¡El premio no fue adquirido!',
  Probability: 'Probabilidad|Probabilidades',
  Process: 'Proceso|Procesos',
  Processed: 'Procesado',
  Product: 'Producto|Productos',
  ProductBatche: 'Lote del producto | Lotes del producto',
  ProductCategory: 'Categoría de producto|Categorías de productos',
  ProductInformation: 'Información del producto',
  Production: 'Producción|Producciones',
  ProductionOrder: 'Órden de Producción|Ordenes de Producción',
  ProductionOrderNotFound: 'Orden de producción no encontrada',
  ProductionOrderProducts: 'Productos de la órden de producción',
  ProductionOrderReport: 'Reporte de Ordenes de Producción',
  ProductionStage: 'Etapa de producción | Etapas de producción',
  ProductSerial: 'Serial del producto | Seriales del producto',
  ProductServiceCategory: 'Categorías de productos o servicios',
  ProgressProductionOrder: 'Ingresa el avance de la orden de producción',
  Promotion: 'Promoción|Promociones',
  ProofOfBalance: 'Balance de Prueba|Balances de Prueba',
  ProofOfBalanceByPerson: 'Balance de Prueba por Tercero|Balances de Prueba por Tercero',
  Prosecute: 'Procesar',
  Purchase: 'Compra|Compras',
  PurchaseAdvance: 'Anticipos en compras',
  PurchaseCreditNote: 'Nota crédito en compras|Notas crédito en compras',
  PurchaseDebitNote: 'Nota débito en compras|Notas débito en compras',
  PurchaseEntry: 'Para compra o entrada',
  PurchaseInvoice: 'Factura de Compra|Facturas de Compra',
  PurchaseDocument: 'Documento de Compra|Documentos de Compra',
  PurchaseOrder: 'Órden de Compra|Ordenes de Compra',
  PurchaseRemission: 'Remisión de Compra|Remisiones de Compra',
  PurchaseRemissionCreate: 'Crear Remisión de Compra',
  PurchaseRemissionEdit: 'Editar Remisión de Compra',
  Quantity: 'Cantidad | Cantidades',
  QuantityProductMustGreater: 'La cantidad del producto debe ser mayor a cero(0)!',
  Quote: 'Cotización|Cotizaciones',
  QuoteFrom: 'Cotización de | Cotizaciones de',
  RangeType: 'Tipo de Rango | Tipos de Rangos',
  Read: 'Leído',
  ReadOnly: 'Solo lectura',
  ReasonReject: 'Motivo de rechazo | Motivos de rechazo',
  RebuildPdf: 'Reconstruir PDF',
  ReceivableAccount: 'Cuenta por Cobrar|Cuentas por Cobrar',
  Receive: 'Recibir',
  Reception: 'Recepción|Recepciones',
  Recipient: 'Destinatario|Destinatarios',
  Reclassification: 'Reclasificación|Reclasificaciones',
  RecommendationDiscount: 'Recomendación: No utilice los descuentos a nivel de producto o ítem al mismo tiempo con un descuento a nivel de factura',
  RecommendedAIUFormat: 'Se recomienda utilizar el “Formato AIU” para la correcta visualización de los conceptos de AIU',
  Record: 'Registro|Registros',
  RecordLinkConfirmation: '¿Realmente quieres vincular el registro?|¿Realmente quieres vincular los registros?',
  RecordLinkedSuccessfully: '¡Registro vinculado con éxito!',
  RecordNotDelete: '¡Oops, No se puede eliminar el registro {value}, el registro está en uso en otros módulos!',
  RecordNotFound: '¡No se encontró el registro!|¡No se encontraron registros!',
  RecordNotModify: '¡Oops, No se puede modificar el registro {value}, el registro está en uso en otros módulos!',
  RecordSuccessfullyCreated: '¡Registro creado exitosamente!',
  RecordSuccessfullyRemoved: 'Registro eliminado con éxito|Registros eliminados con éxito',
  RecordSuccessfullySaved: 'Registro guardado con éxito|Registros guardados con éxito',
  RecordSuccessfullyUpdated: 'Registro actualizado con éxito|Registros actualizados con éxito',
  RecurrenceInvoice: 'Factura recurrente|Facturas recurrentes',
  Redeem: 'Redimir',
  Reference: 'Referencia|Referencias',
  Refresh: 'Refrescar',
  Refund: 'Devolución | Devoluciones',
  Region: 'Región | Regiones',
  RegionIsRequired: '¡La región es obligatoria!',
  Register: 'Caja | Cajas',
  RegisterClosure: 'Cierre de caja | Cierres de caja',
  RegisterElectronicBiller: 'Registrarme como nuevo facturador electrónico',
  Reject: 'Rechazar',
  RelatedContacts: 'Contactos relacionados',
  Remark: 'Observación | Observaciones',
  RememberMe: 'Recordarme',
  Reminder: 'Recordatorio',
  Remisioned: 'Remisionado|Remisionados',
  RemissionPurchase: 'Remisión en compras',
  Remove: 'Remover',
  RemoveBoxeFromThisBranch: 'Eliminarás {value} y las cajas de esta sucursal. ¿Continuar?',
  RemoveConcept: 'Remover este concepto',
  RemoveProductPhoto: 'Eliminar foto del producto',
  RemoveCategoryImage: 'Eliminar imagen de la categoría',
  RemoveValue: 'Eliminarás {value}',
  RenumberAccountingDocument: 'Renumerar asiento',
  Renumerated: 'Renumerado',
  ReOrderLevel: 'Nivel de re-orden',
  Replace: 'Remplazar',
  Replaced: 'Reemplazada',
  Report: 'Reporte|Reportes',
  ReportHelper: 'Si llevas más de tres años con Aliaddo, debes tener el antepenúltimo año fiscal con cierre contable, es decir que si estás sacando un balance prueba del año 2015, debes tener el año 2013 cerrado contablemente, esto es para que el sistema muestre el balance con saldos reales.',
  ReportOf: 'Reporte de|Reportes de',
  ReportSuccessfullyGenerated: '¡Reporte generado exitosamente!|¡Reportes generados exitosamente!',
  RequesterName: 'Nombre solicitante',
  RequestNumberingResolution: 'Solicitar resolución de numeración',
  RequestType: 'Tipos de solicitudes',
  Required: 'Requerido | Requeridos',
  RequiredBranchSelling: 'Requerido si en esta sucursal se venderá a través del POS',
  RequiredFiledMessage: 'El campo Mensaje es obligatorio',
  Requisition: 'Requisición|Requisiciones',
  ResetTo: 'Restablecer a',
  ResidualValue: 'Valor residual | Valores residuales',
  Resolution: 'Resolución|Resoluciones',
  ResolutionsExpired: 'Resoluciones de numeración vencidas o por vencer',
  ResponsePath: 'Ruta de respuesta| Rutas de respuestas',
  RetentionIca: 'Retención ICA',
  RetentionIva: 'Retención IVA',
  Retired: 'Retirado',
  Rol: 'Rol | Roles',
  Rounding: 'Redondeo',
  RoutingCode: 'Código de ruta',
  RUT: 'RUT',
  Salary: 'Salario',
  Sale: 'Venta|Ventas',
  SaleByBranch: 'Venta por Sucursal|Ventas por Sucursal',
  SaleByCostCenter: 'Venta por Centro de Costos|Ventas por Centro de Costos',
  SaleByCustomer: 'Venta por Cliente|Ventas por Cliente',
  SaleByPerson: 'Venta por Cliente|Ventas por Cliente',
  SaleByPos: 'Venta por Punto de Venta|Ventas por Punto de Venta',
  SaleByProduct: 'Venta por Producto|Ventas por Producto',
  SaleBySeller: 'Venta por Vendedor|Ventas por Vendedor',
  SaleCreditNote: 'Nota crédito en ventas|Notas crédito en ventas',
  SaleDebitNote: 'Nota débito en ventas|Notas débito en ventas',
  SaleInvoice: 'Factura de venta | Facturas de ventas',
  SaleOrder: 'Órden de venta|Ordenes de venta',
  SaleRemission: 'Remisión de venta|Remisiones de venta',
  SalesOrder: 'Orden de venta',
  SalesOrderFrom: 'Órden de venta de | Ordenes de venta de',
  SalesSummary: 'Resumen de Ventas',
  SaleSummary: 'Resumen de Ventas',
  Salutation: 'Saludo|Saludos',
  Saturday: 'Sabado',
  Save: 'Guardar',
  Schedule: 'Programar',
  ScheduleBackup: 'Agendar Copia de Respaldo',
  Scheduled: 'Programado|Programados',
  Search: 'Buscar',
  SearchAndSelectProduct: 'Búsca y selecciona un producto',
  SearchByEmployee: 'Buscar por empleado',
  SearchByPeriod: 'Buscar por periodo',
  SearchContact: 'Buscar contacto|Buscar contactos',
  SearchDeal: 'Buscar negocio',
  SearchForPerson: 'Buscar por tercero|Buscar por terceros',
  SearchName: 'Buscar por nombre',
  SearchPersonPlaceholder: 'Nombre, Identificación, Email, Teléfono',
  SearchPersonTitle: 'Buscar por nombre, identificación, email o teléfono',
  SearchPerson: 'Buscar tercero',
  SearchPlaceholder: 'Buscar...',
  SecondName: 'Segundo nombre',
  SecondSurname: 'Segundo apellido',
  See: 'Ver',
  SeeAccounting: 'Ver contabilidad',
  SeeAllFolders: 'Ver todas las carpetas',
  SeeAttachment: 'Ver adjunto | Ver adjuntos',
  SeeBoxe: 'Ver cajas',
  SeeCreditNote: 'Ver nota crédito en venta',
  SeeDebitNote: 'Ver nota débito en venta',
  SeeDianMessages: 'Ver mensajes DIAN',
  SeeDocument: 'Ver documento | Ver documentos',
  SeeEmail: 'Ver e-mail',
  SeeFile: 'Ver archivo',
  SeeFormat: 'Ver formatos',
  SeeHistory: 'Ver histórico',
  SeePaymentAndNote: 'Ver Pagos y notas',
  SeeSerial: 'Ver serial | Ver seriales',
  SeeStock: 'Ver existencia| Ver existencias',
  Select: 'Seleccionar',
  SelectA: 'Selecciona un',
  SelectAddress: '¡Selecciona una dirección!',
  SelectAnAccount: 'Selecciona una cuenta',
  SelectAnIcon: 'Seleccione un icono',
  SelectAnOption: 'Selecciona una opción',
  SelectAtLeastOneDocument: '¡Seleccione al menos un documento!',
  SelectBatchForProduct: 'Debes seleccionar un lote para el producto',
  SelectBranch: 'Selecciona una sucursal',
  SelectBranchAndDocumentType: 'Selecciona una sucursal y un tipo de documento',
  SelectBranchFirst: '¡Seleccione una sucursal primero!',
  SelectCity: 'Selecciona una ciudad',
  SelectCode: '¡Selecciona un código!',
  SelectCostCenter: 'Selecciona un centro de costo',
  SelectCountry: 'Selecciona un país',
  SelectCurrency: 'Selecciona una moneda',
  SelectCustomer: '¡Selecciona un cliente!',
  SelectCustomerFirst: '¡Seleccione un cliente primero!',
  SelectDate: 'Selecciona una fecha',
  SelectDateRange: 'Selecciona el rango de fechas de inicio a fin',
  SelectDealCloseDate: 'Selecciona una fecha de cierre para el negocio',
  SelectDocumentType: 'Selecciona un tipo de documento',
  Selected: 'Seleccionados',
  SelectedDocumentNotPaid: '¡El documento seleccionado no se puede pagar! | ¡Los documentos seleccionados no se pueden pagar!',
  SelectedSoldSerial: 'Has seleccionado {itemSerial} seriales que ya han sido vendidos',
  SelectEMailSender: '¡Selecciona un remitente de e-mail!',
  SelectEMailTemplate: 'Selecciona una plantilla e-mail',
  SelectExpirationDate: '¡Selecciona una fecha de vencimiento!',
  SelectFile: 'Selecciona un archivo| Seleccionar archivos',
  SelectFolder: 'Seleccionar Carpeta',
  SelectIdType: 'Selecciona un tipo de id',
  SelectItem: '¡Selecciona un Item!',
  SelectListFirst: '¡Selecciona una lista primero!',
  SelectLossReason: 'Selecciona un Motivo de pérdida',
  SelectMandator: '¡Selecciona un mandante!',
  SelectOneAdvance: '¡Selecciona al menos un anticipo!',
  SelectOneDocument: '¡Selecciona al menos un documento!',
  SelectOneToActivate: 'Selecciona uno para activarlo',
  SelectOutcome: 'Selecciona un resultado',
  SelectPaymentMethod: '¡Selecciona un medio de pago!',
  SelectPhoto: 'Seleccionar foto',
  SelectPriceList: 'Seleccione una lista de precio',
  SelectProductionOrder: '¡Selecciona al menos una orden de producción!',
  SelectProductPhoto: 'Selecciona foto del producto',
  UploadCategoryImage: 'Subir imagen de la categoría',
  SelectRegion: 'Selecciona una región',
  SelectSellerFirst: 'Selecciona primero un vendedor',
  SelectSender: '¡Selecciona un remitente!',
  SelectSenderAndMailTemplate: '¡Selecciona al un remitente y una plantilla de correo!',
  SelectSupplier: '¡Selecciona un proveedor!',
  SelectTemplate: '¡Selecciona una plantilla!',
  SelectTheStartToEndDateRange: 'Selecciona el rango de fechas de inicio a fin',
  SelectValidAccount: '¡Selecciona una cuenta valida!',
  SelectWarehouse: 'Selecciona una bodega',
  Sell: 'Vender',
  Seller: 'Vendedor | Vendedores',
  SellerRequiredSale: '¿El vendedor es requerido en la venta?',
  Send: 'Enviar',
  SendDocumentPeriod: 'Estamos enviando los documentos del periodo, espera un momentico',
  SendEmail: 'Enviar e-mail',
  Sender: 'Remitente|Remitentes',
  SenderEMail: 'E-mail remitente',
  SendNotificationTo: 'Enviar notificación a {0}',
  SendPdfCustomer: 'Enviar PDF al cliente',
  SendThe: 'Enviado el',
  Sent: 'Enviado | Enviada',
  September: 'Septiembre',
  Serial: 'Serial|Seriales',
  SerialNumber: 'Serial No. | Número del serial',
  SerialQuantityMatchProductQuantity: 'La cantidad de seriales debe coincidir con la cantidad del producto',
  SerialsReport: 'Reporte de seriales',
  SerialTransactionsReport: 'Reporte de transacciones de seriales',
  ServiceCode: 'Código de servicio',
  ServiceNotAvailable: 'Servicio de la DIAN no disponible, por favor volver a transmitir',
  SetNewPassword: 'Asignar nueva contraseña',
  Setting: 'Configuración|Configuraciones',
  SettlementEndDate: 'Fecha liquidación fin',
  SettlementStartDate: 'Fecha liquidación inicio',
  Short: 'Baja',
  Shortcut: 'Acceso directo | Accesos directos',
  ShortcutName: 'Nombre del acceso rápido',
  ShowingRecordsFor: 'Mostrando resultados para',
  Signature: 'Firma | Firmas',
  Sold: 'Vendido | Vendida',
  SoldItem: 'Artículo vendido | Artículos vendidos',
  Solution: 'Solución | Soluciones',
  Source: 'Fuente | Fuentes',
  Stage: 'Etapa',
  Start: 'Inicio',
  StartDate: 'Fecha inicial | Fecha de inicio',
  StartHour: 'Hora de inicio',
  Status: 'Estado|Estados',
  StatusChange: 'Cambio de estado | Cambios de estado',
  Step: 'Paso',
  Stock: 'Stock',
  StocksEqualInTheItem: 'Los stocks debe ser igual en el ítem',
  Subject: 'Asunto | Asuntos',
  Subledger: 'Libro Auxiliar|Libros Auxiliares',
  OfficialBook: 'Libro Oficial| Libros oficiales',
  SubtitleAddAliaddoProvider: 'Para seleccionar a "Aliaddo" como proveedor tecnológico, debes ingresar en el portal de HABILITACION de la DIAN en el siguiente enlace',
  SubtitleRegisterElectronicBiller: 'Para registrarse como facturador electrónico debes ingresar en el portal de HABILITACION de la DIAN en el siguiente enlace',
  SubtitleUpdateCompany: 'Para habilitarte debes actualizar los datos de tu empresa, haz click en el botón de abajo',
  SubTotal: 'Subtotal | Subtotales',
  SuccessfulEmailSending: '¡E-mail enviado con éxito!',
  SuccessfullyExported: '¡Exportado exitosamente!',
  SuccessfullySaved: 'Guardado exitosamente',
  SuccessfullyUpdatedPrice: '¡Precio actualizado con éxito!|¡Precios actualizados con éxito!',
  SumValueNotEqualItem: '¡La sumatoria de los valores en el A.I.U no son iguales al valor del Item!',
  Sunday: 'Domingo',
  SuperiorManufacturingDate: 'Fecha manufactura superior a fecha vencimiento',
  Supplier: 'Proveedor | Proveedores',
  SupplierAccountStatement: 'Estado de cuenta de proveedores',
  Support: 'Soporte',
  SupportingDocument: 'Documento soporte',
  SureApproveRegistration: '¿Está seguro de aprobar el registro?',
  SureDeleteRecord: '¿Estás seguro de eliminar el registro?',
  SureDownloadDocument: '¿Está seguro de querer enviar el documento?. ¡Presione OK para continuar o presione Cancelar para no realizar la acción!',
  SureDownloadPdf: '¿Está seguro de querer descargar el PDF?. ¡Presione OK para continuar o presione Cancelar para no realizar la acción!',
  SureDownloadXml: '¿Está seguro de querer descargar el XML?. ¡Presione OK para continuar o presione Cancelar para no realizar la acción!',
  SurePerformAction: '¿Está seguro de realizar esta acción?',
  SureProcessedRegistration: '¿Está seguro de procesar el registro?',
  SureUnregister: '¿Está seguro de anular el registro?',
  Surname: 'Apellido | Apellidos',
  Survey: 'Encuenta | Encuestas',
  Surveys: 'Encuestas',
  Tag: 'Etiqueta | Etiquetas',
  TagAgente: 'Etiqueta agente',
  Tariff: 'Tarifa | Tarifas',
  Tax: 'Impuesto|Impuestos',
  Withholding: 'Retención|Retenciones',
  TaxesAndWithholding: 'Impuestos y Retenciones',
  TaxesReport: 'Reporte de impuestos',
  TaxpayerSubtype: 'Subtipo Contribuyente',
  TaxpayerType: 'Tipo Contribuyente',
  TechnologySupplier: 'Proveedor Tecnológico : ALIADDO SAS NIT:830099008-5 - www.aliaddo.com',
  Template: 'Plantilla | Plantillas',
  TermAndCondition: 'Término y condición | Términos y condiciones',
  TermConditionSaleOrden: 'Términos y condiciones para orden de venta',
  TermConditionSaleOrder: 'Términos y condiciones para orden de venta',
  TermConditionSaleRemission: 'Términos y condiciones para remisión de venta',
  Terminated: 'Terminada|Terminadas',
  Territory: 'Territorio | Territorios',
  Test: 'Prueba | Pruebas',
  TestSet: 'Set de prueba',
  Thursday: 'Jueves',
  TicketFields: 'Campos del ticket',
  TicketId: 'Ticket Id',
  TicketResponse: 'Repuesta del ticket',
  Tickets: 'Tickets',
  TicketType: 'Tipo de ticket',
  Time: 'Tiempo',
  Title: 'Título',
  To: 'Hasta',
  ToAccount: 'A la cuenta|A las cuentas',
  ToAssign: 'Asignar',
  ToBeDefeated: 'Por vencer',
  ToClose: 'Cerrar',
  ToConsecutive: 'Hasta el Consecutivo',
  Today: 'Hoy',
  ToInvoice: 'A Factura | A Facturas',
  ToMonth: 'Hasta el Mes',
  Tomorrow: 'Mañana',
  ToPurchaseInvoice: 'A factura de compra',
  ToPurchaseRemission: 'A remisión de compra',
  ToRemission: 'A remisión | A remisiones',
  Total: 'Total|Totales',
  TotalCharge: 'Total cargos',
  TotalPayment: 'Total pagado| Total pagos',
  ToUpdate: 'Actualizar',
  Traceability: 'Trazabilidad',
  TraceabilityHelper: '¡RECUERDA! La trazabilidad sólo dura un máximo de 90 días.',
  TransferBatchEdit: 'Editar - Traslado de inventario de lotes',
  Transmit: 'Transmitir',
  Transfer: 'Traslado|Traslados',
  Treasury: 'Tesorería',
  TreasuryByAccount: 'Tesorería por cuenta',
  TreasuryPaymentGroupingHelper: 'Tu cuenta por pagar o cobrar está agrupada por tercero y mes.',
  TreasuryThirdParty: 'Tesorería por tercero',
  TRM: 'TRM',
  Tuesday: 'Martes',
  TutorialAddAliaddoTechnologyProvider: 'Instrucciones para seleccionar a "Aliaddo" como proveedor tecnológico',
  TutorialAsociatePrefix: 'Instrucciones para asociar la resolución de numeración.',
  TutorialGeneratingResolution: 'Instrucciones para crear una nueva resolución de numeración de facturación.',
  TutorialRegisterElectronicBiller: 'Instrucciones para registrarme como nuevo facturador electrónico',
  Tutorials: 'Tutorial|Tutoriales',
  Type: 'Tipo|Tipos',
  TypeMinimumCharacters: 'Ingresa mínimo {0} caracteres',
  MaxCharacters: 'El campo {date} debe tener un máximo de {charcters} caracteres.',
  TypeNote: 'Tipo nota',
  TypeSalary: 'Tipo salario',
  TypeOfAnswer: 'Tipo de repuesta',
  UM: 'U/M',
  Uncapped: 'Sin tope limite',
  UnderReOrderLevel: 'Exportar únicamente los que están debajo del nivel de re-orden',
  UnexpectedEvent: 'Oops, ha ocurrido algo inesperado',
  Unforeseen: 'Imprevisto | Imprevistos',
  UnitValue: 'Vr. Unitario | Valor unitario',
  Unknown: 'Desconocido|Desconocidos',
  UnlinkedPayment: 'Pagos no vinculados o con asientos contables anulados',
  UnlinkedPaymentHelper: 'Estos pagos no están vinculados a una cuenta por pagar o una cuenta por cobrar, por la tanto, la obligación sigue activa sin pagar.',
  Unlocked: 'Desbloqueado',
  UnpaidOrPartialPayment: 'No pagado o pago parcial',
  Update: 'Actualizar',
  UpdateAgent: 'Actualizar agente',
  UpdateCompanyData: 'Actualiza los datos de tu empresa',
  UpdateOwner: 'Actualizar propietario',
  UpdatePriority: 'Actualizar prioridad',
  UpdateStatus: 'Actualizar estado',
  UploadFile: 'Subir archivo|Subir archivos',
  UploadImage: 'Subir Imagen',
  UploadPaymentVoucher: 'Subir comprobante de pago | Subir comprobantes de pago',
  UploadSignature: 'Subir firma| subir firmas',
  UploadTheSignature: 'Por favor carga y sube la firma',
  UpToDate: 'Hasta el día',
  Urgent: 'Urgente',
  Use: 'Usar',
  UsedCredit: 'Crédito usado',
  User: 'Usuario | Usuarios',
  UserInformation: 'Información del usuario',
  UserProfile: 'Perfil de usuario | Perfiles de usuario',
  UserTrade: 'Usuario (comercio)',
  UsingDocument: 'Usar documento | Usar documentos',
  Utility: 'Utilidad|Utilidades',
  Valid: 'Válido|Válidos',
  ValuationAccount: 'Cuenta valorización',
  Value: 'Valor',
  ValueDeal: 'Valor del negocio',
  ValueDiscountIsNotGreat: '¡El valor de descuento es demasiado grande!',
  ValueNotValid: '¡El valor no es válido!',
  Variant: 'Variante|Variantes',
  Variation: 'Variación|Variaciones',
  VerifySerialSold: 'Los seriales {itemSerialNumbers} ya fueron vendidos o tienen una orden de producción, verifica',
  ViewDocumentCustomer: 'Ver documento como cliente',
  VirtualZone: 'Zona Virtual',
  Visit: 'Visitar | Visite',
  Volume: 'Volumen | Volumenes',
  VoucherType: 'Tipo de comprobante|Tipos de comprobantes',
  WaitingDelivery: 'Para domicilio, en espera',
  WaitingPickup: 'Para entrega, en espera',
  Warehouse: 'Bodega|Bodegas',
  Website: 'Sitio web',
  Wednesday: 'Miercoles',
  Weight: 'Peso | Pesos',
  WereLeft: 'Quedaron',
  willRemove: 'Se eliminará',
  WishToContinue: '¿Deseas continuar?',
  WithCreditNote: 'Con nota crédito',
  WithDebitNote: 'Con nota débito',
  WithdrawalDate: 'Fecha finalización contrato',
  WithholdingCertificate: 'Certificados de retención | Certificado de retenciones',
  WithoutDescription: 'Sin descripción',
  WorkingHour: 'Horario Laboral | Horarios Laborales',
  WorkingYourReferralPartAliaddo: '¡Estamos trabajando para que tu referido haga parte de Aliaddo!',
  Wrong: 'Error',
  WrongDayNumberEntered: 'Ingresaste un día mayor a 31, se usará 31 en su lugar.',
  Year: 'Año|Años',
  Yes: 'Sí',
  Yesterday: 'Ayer',
  YourAccount: 'Tu cuenta',
  YourAccountingAccountIsGrouped: 'Tu cuenta por pagar/cobrar esta agrupado por tercero y mes',
  YourCollection: 'Tu colección',
  ZipCode: 'Código postal | Códigos Postales',
  DeskPanel: 'Panel de soporte',
  AlertReportMinimumQuantity: 'Reportes de alerta para cantidades minimas',
  PushNotification: 'Notificación push | Notificaciones push',
  PushNotificationCreate: 'Crear notificación | Crear notificaciones',
  SuccessEmailConfirm: 'E-mail confirmado con éxito ',
  QueueBackgroundWorkItemSuccess: 'Tu tarea se va a procesar en segundo plano, mientras continuas trabajando te avisaremos al email con el que ingresaste cuando terminemos el proceso',
  ReplySubject: 'Asunto de la respuesta',
  CustomersSatisfaction: 'Encuesta de satisfacción',
  ticketConsecutive: 'Número del ticket',
  Substate: 'Subestado|Subestados',
  PasswordManagement: 'Administración de contraseña | Administración de contraseñas',
  CustomState: 'Estado personalizado | Estados personalizados',
  withoutStatus: 'Sin Estado | Sin estados',
  SurveyDetails: 'Detalle de encuesta | Detalles de encuestas',
  CapitalLetter: 'Mayúscula (ABC) | Mayúsculas (ABC)',
  Lowercase: ' Minúscula (abc) | Minúsculas (abc)',
  TypeNumber: 'Numero (123) | Numeros (123)',
  SpecialCharacter: 'Caracter especial (!#$) | Caracteres especiales (!#$)',
  ReportArticle: 'Reporte de articulo | Reportes de articulos',
  PerformanseAgent: 'Rendimiento por agente | Rendimientos por agentes',
  PerformanseGroup: 'Rendimiento por grupo | Rendimientos por grupos',
  knowledgeBase: 'Base de conocimiento | Bases de conocimientos',
  reportByArticles: 'Reporte por artículo | Reportes por artículos',
  ReportByAgent: 'Reporte por agente | Reportes por agentes',
  ReportBygroup: 'Reporte por grupo | Reportes por grupos',
  SurveyReport: 'Reporte por encuesta | Reportes por encuestas',
  Panel: 'Panel',
  PanelSummary: 'Panel resumen de ticket',
  EmailTemplateFromCRM: 'Plantilla de email del CRM|Plantillas de email del CRM',
  DetailedInvoice: 'Factura detallada',
  DetailedItem: 'Producto detallado',
  CreditCardIsSecure: 'Tus datos estan seguros',
  CreditCardIsSecureHelper: 'Los medios de pago inscritos en Aliaddo no son almacenados con datos completos de tus tarjetas',
  DetailedPerson: 'Tercero detallado',
  View360: 'Vista 360°',
  Profitability: 'Rentabilidad',
  Hour: 'Hora | Horas',
  CompanyGroups: 'Grupos de la empresa',
  AddCreditCard: 'Adicionar Tarjeta',
  CardSubscribedDeleteActive: 'Cuando eliminas tu medio de pago predeterminado para el pago, el siguiente medio de pago en la lista va a quedar como predeterminado',
  CardSubscribedDeleteAll: 'Al eliminar todos tus medios de pagos no podremos realizar el cobro automático de tus servicios adquiridos, esto puede incurrir en que tu cuenta pase a modo lectura en caso tal que no realices el pago manual antes de la fecha de límite',
  MarkAsDefault: 'Establecer como predeterminada',
  UnMarkAsDefault: 'Quitar como predeterminada',
  UnMarkAsDefaultHelp: 'Si desmarcas tu tarjeta como predeterminada no podremos realizar el cobro automático, tendras que  realizar el pago manual antes del día de vencimiento de tu suscripción.',
  IsDefault: 'Predeterminada',
  Rejected: 'Rechazada',
  MyCardSubscribed: 'Mis tarjetas inscritas',
  NameHolder: 'Nombre Titular',
  EnterNameHolder: 'Ingresa Nombre Titular',
  CardNumber: 'Número Tarjeta',
  EnterCardNumber: 'Ingresa Número Tarjeta',
  ExpirationDateCard: 'Fecha Expiración',
  SecurityCode: 'Código Seguridad',
  EnterSecurityCode: 'Ingresa Código Seguridad',
  Dues: 'Cuotas',
  EnterDues: 'Ingresa Cuotas',
  EnterCreditNumberValid: 'Ingrese un número de tarjeta valido',
  Business: 'Empresa|Empresas',
  CreditCardData: 'Datos Tarjeta Crédito',
  CreditCardHolderData: 'Datos Titular Tarjeta',
  IdentificationDocumentType: 'Tipo Documento Identidad',
  Enter: 'Ingresa',
  CellPhone: 'Número Celular',
  DataPayer: 'Datos Pagador',
  BillingData: 'Datos Facturación',
  Preferences: 'Preferencias',
  Helpdesk: 'Mesa de ayuda',
  HelpdeskReport: 'Reportes mesa de ayuda',
  TicketDetail: 'Detalle de ticket'
}
